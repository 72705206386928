.main-profile-link-group {
  display: flex;
  gap: 1em;
  flex-direction: column;
  align-self: flex-start;
  font-family: var(--paragraph-font);
  margin-top: 20px;
}

.user-profile-container {
  display: flex;
  gap: 1em;
  flex-direction: column;
  width: 100%;
  max-width: var(--slim-desktop-width);
  align-self: center;
}

.main-profile-link,
.main-profile-link:hover,
.main-profile-link:visited {
  color: var(--main-text-color);
  text-decoration: none;
  display: flex;
  gap: 0.5em;
}

.main-profile-link p {
  margin-top: 10px;
}

.user-profile-pic {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  align-self: center;
}

.profile-content {
  width: 40%;

  padding: 50px 10px 10px 14px;
}
