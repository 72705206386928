.calendar-container {
  /* width: 25em; */
  /* margin-top: -30px; */
  /* background-color: #222; */
  border-radius: 8px;
  padding: 20px;

  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
}

.selected {
  background-color: #7fff00;
  color: #000;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.month-name {
  font-size: 1.2em;
  font-weight: bold;
}

.nav-arrow {
  background-color: transparent;
  border: none;
  color: #7fff00;
  font-size: 1.5em;
  cursor: pointer;
}

.calendar-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  color: #7fff00;
  border-bottom: 1px solid gray;
}

.calendar-dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  gap: 10px;

  /* border-bottom: 1px solid gray; */
}

.calendar-dates span {
  padding: 8px;
  /* width: 50px;
  height: 50px; */
  border-radius: 100%;
  cursor: pointer;
}

.prev-month {
  color: #555;
}

.current-day {
  background-color: transparent;
  border: 1px solid #7fff00;
  /* border-bottom: 1px solid gray; */
}

.selected {
  background-color: #7fff00;
  color: #000;
}

.meal-container {
  width: 90%;
  max-width: 600px;
}

.meal-item {
  background-color: #111;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  position: relative;
}

.meal-item h2 {
  margin: 0 0 10px;
  font-size: 1.2em;
  text-transform: uppercase;
}

.meal-details {
  margin-bottom: 10px;
}

.meal-calories {
  font-size: 0.9em;
  color: #888;
  font-family: var(--title-font);
  color: var(--neutral-white-200, #f6f6f6);
}

.checkmark {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 1.5em;
  color: #7fff00;
  border: 2px solid #7fff00;
  border-radius: 50%;
  /* width: 24px;
  height: 24px; */
  text-align: center;
  line-height: 20px;
  padding: 2px;
}

.checkmark.incomplete {
  color: #fff;
  border: 2px solid #fff;
  border-radius: 100%;
  padding: 14px;
  text-align: center;
  line-height: 20px;
  font-size: 18px;
}

.typeCls {
  color: var(--neutral-white-200, #f6f6f6);

  /* Headings/h5 */
  font-family: var(--title-font);
}

.cins {
  font-family: var(--title-font);
  color: var(--neutral-white-200, #f6f6f6);
}
