/* src/Accordion.css */
.accordion {
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  width: 100%;

  margin: 0 auto;
  border: none;
}

.accordion-item {
  border: none;
  margin-bottom: 10px;
  border-radius: 8px;
}

.accordion-header {
  background: var(--neutral-black-800);
  font-family: var(--paragraph-font);
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.accordion-header img {
  filter: invert(100%) brightness(10) saturate(10%) brightness(4);
}

.accordion-body {
  padding: 10px;
  color: #fff;
  background-color: #111;
  font-family: var(--paragraph-font);
}
