@font-face {
  font-family: "Familjen Grotesk Regular";
  src: url("../assets/fonts/FamiljenGrotesk-Variable.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bebas Neue Regular";
  src: url("../assets/fonts/BebasNeue-Regular.ttf") format("truetype"),
    url("../assets/fonts/BebasNeue-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --title-font: "Bebas Neue Regular", sans-serif;
  --button-font: "Bebas Neue Regular", sans-serif;
  --paragraph-font: "Familjen Grotesk Regular", sans-serif;
}

/* Header.css */
.header {
  background: var(--neutral-black-800, #080808);
  display: flex;
  align-items: center;
}

.logo {
  padding: 10px;
  margin-left: 30px;
  width: 35px;
  height: 35px;
}

/* Sidebar.css */
.sidebar {
  height: 100%;
  width: 200px;
  background-color: #0f0f0f;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: 60px;
  padding-left: 40px;
}

.sidebar-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.sidebar-item img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  filter: invert(100%) brightness(10) saturate(10%) brightness(4);
}

.sidebar-item span {
  font-size: 30px;
  font-family: var(--title-font);
}

.sidebar-item.active {
  color: #a0fc6e;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    padding: 10px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #0f0f0f;
    z-index: 100;
    position: fixed;
  }

  .sidebar-item {
    margin: 0;
    padding: 10px;
  }

  .sidebar-item span {
    font-size: 12px;
  }
}

/* Main.css */
.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container {
  display: flex;
  flex: 1;
}

.main {
  flex: 1;
}

.topBar {
  background-color: black;
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  height: 50px;
}

.topBar img {
  margin-left: 20px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.topBar span {
  margin-left: 10px;
  font-size: 35px;
  font-family: var(--title-font);
}

.table-top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex: 1;
  display: flex;
}

.entries-search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-left: 30px;
  margin-right: 30px;
}

.entries-dropdown {
  margin-right: 10px;
}

.search-filter input[type="text"] {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 150px;
  background-color: transparent;
  font-family: var(--paragraph-font);
}

.entries-dropdown select {
  background: #222222;
  width: 50px;
  color: white;
  border-radius: 2px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

label {
  font-family: var(--paragraph-font);
}

.mainContent {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content-columns {
  margin-top: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.content-columns .content-center {
  width: 75%;
}
.background-for-notes {
}
.content-columns .content-right-sidebar {
  padding: 10px;
  display: flex;
  width: 25%;
  flex-direction: column;
  gap: 0.5em;
  min-height: 200px !important;
  background: var(--main-background-gradient);
}
.right-sidebar {
  background: var(--main-background-gradient);
}
.content-columns .content-right-sidebar .button-default-no-icon {
  width: 90%;
}

.content-right-sidebar .button-default-no-icon {
  width: 30%;
  margin-top: 30px;
}

.content-columns .content-right-sidebar .task-body {
  display: flex;
  gap: 1em;
}

.task-editor .title {
  margin-bottom: 10px;
}

.task-editor .task-body {
  margin-bottom: 10px;
}

.content-columns .content-right-sidebar .card .title > img {
  margin-right: 0.3em;
}

.content-columns .content-right-sidebar .card .column {
  flex-direction: column;
}

.content-columns .content-right-sidebar .card .tags.column {
  align-items: center;
}

.content-columns .content-right-sidebar .card .tags.column .tag {
  width: 90%;
}

/* Calendrio */
.calendrioContainer {
  margin-bottom: 100px;
}
table {
  width: 93%;
  background: var(--neutral-black-800, #080808);
  margin-left: 30px;
  border-spacing: 0;
  font-family: var(--paragraph-font);
}

.custom-table th {
  border: 1px solid grey;
  padding: 10px;
  text-align: left;
}

.custom-table td label {
  background-color: var(--blue-secondary-700);
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 10px;
}

.custom-table td {
  font-family: var(--paragraph-font);
  font-size: 15px;
  color: var(--main-text-color);
  padding: 10px;
}

.headerCalendrio {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-top: 30px;
}

.backArrow {
  margin-right: 20px;
}

.titleText {
  font-size: 35px;
  font-family: var(--title-font);
}

.button-default-no-icon img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.button-default-no-icon p {
  margin-top: 20px;
}

.calendar-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 70%;
  margin-left: 30px;
}

.horiLine {
  border-bottom: 1px solid #a0fc6e;
  width: 60%;
  margin-left: 30px;
  margin-bottom: 40px;
}

.calendar-group > div {
  flex-basis: 100%;
  padding: 1em;
  border: 1px solid transparent;
}

.calendar-group > div > a {
  margin-top: 1em;
}

.calendar-group > div > .title {
  font-family: var(--title-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.calendar-group .green-border {
  border: 1px solid var(--green-primary-500);
  border-radius: 8px;
}

.calendar-group .task-editor {
  display: flex;
  flex-direction: column;
  gap: 1em;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding-top: 1em;
  padding-bottom: 1em;
}

.calendar-group .task-body {
  display: flex;
  gap: 1em;
}

.task-editor .task-body {
  font-family: var(--title-font);
}

.task-editor label {
  font-family: var(--paragraph-font);
  margin-top: 5px;
}

@media screen and (min-width: 768px) {
  .calendar-group > div {
    flex-basis: 30%;
  }
}

.content-columns .content-right-sidebar > .title {
  font-size: 29px;
}

.content-right-sidebar .card {
  background: var(--main-background-gradient);
  display: flex;
  flex-direction: column;
  padding: 1em 0.5em;
  gap: 1em;
  /* width: 30%; */
}

@media (max-width: 768px) {
  .content-right-sidebar .card {
    width: 50%;
  }
}

.content-right-sidebar .card .top,
.content-right-sidebar .card .bottom {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.content-right-sidebar .card .title {
  color: var(--neutral-white-200, #f6f6f6);
  font-family: var(--title-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content-right-sidebar .card .description {
  color: var(--neutral-white-200, #f6f6f6);
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
}

.content-right-sidebar .card .bottom {
  display: flex;
  justify-content: space-between;
}

.content-right-sidebar .task-editor {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.table p {
  font-size: 15px;
  font-family: var(--paragraph-font);
  margin-left: 30px;
}

.bottomTextHome {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottomRightText {
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  align-items: center;
}

.bottomRightCount {
  border: 1px solid grey;
  justify-content: center;
  width: 40px;
  height: 25px;
  text-align: center;
  padding-top: 3px;
}

.bottomRightText p {
  color: gray;
}

.table-link {
  text-decoration: none;
  color: inherit;
}

.total-group {
  display: flex;
  justify-content: space-between;
}

.total-group > div {
  max-width: 45%;
}

.coach-calendar {
  margin-top: 30px;
}

/* CustomModal */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal {
  background: black;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.custom-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #a0fc6e;
}

.custom-modal-body {
  padding: 20px 0;
}

.custom-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.text {
  color: white;
}

.customButtonSend {
  background-color: var(--blue-secondary-700);
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.customButtonClose {
  background-color: white;
  /* border: none; */
  outline: none;
  padding: 10px;
  border-radius: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
  color: #a0fc6e;
}

.modal-actions {
  margin-top: 20px;
}

.save-button,
.cancel-button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

.save-button {
  background-color: #a0fc6e;
  color: white;
}

.cancel-button {
  background-color: gray;
  color: #fff;
}

.recipeText {
  font-size: 20px;
  font-family: var(--paragraph-font);
  color: #a0fc6e;
}

/* Loader */
header {
  height: var(--mobile-header-height);
  background-image: var(--main-background-gradient);
  display: flex;
  flex-direction: row;
  padding: var(--mobile-header-padding);
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

header .header-container {
  width: 100%;
  max-width: var(--max-site-width);
  margin-left: auto;
  margin-right: auto;
}

header .logo-icon {
  position: relative;
  width: 19px;
  height: 35px;
}

.athlete-table {
  width: 100%;
  border-collapse: collapse;
  color: #fff;
  background-color: #222;
}

.athlete-table th,
.athlete-table td {
  padding: 10px;
  border: 1px solid #444;
  text-align: left;
}

.athlete-table th {
  background-color: #333;
}

.tags {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 4px 0;
  color: var(--main-text-color);
}

.tags .tag {
  font-family: var(--paragraph-font);
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--blue-secondary-700);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.tags .tag.outline {
  background: transparent;
  color: var(--main-text-color);
  border: 1px solid var(--blue-secondary-700);
}

.tags .tag sup {
  position: relative;
  top: -0.5em;
  margin-right: 1px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag-input {
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #1e90ff;
  color: white;
  margin-bottom: 5px;
}

.add-tag-btn {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 1px;
}

.add-tag-btn:hover {
  background-color: #218838;
}
