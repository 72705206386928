.main-container.centered {
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; */
  /* background-color: #f7f7f7; */
}

.otp-content {
  /* background: white; */
  padding: 2em;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.headline {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.otp-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  margin: 2em;
}

.otp-field {
  width: 3em;
  height: 3em;
  font-size: 1.5em;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0.5em;
}

.button-default-no-icon {
  background-color: #007bff;
  color: white;
  padding: 0.5em 2em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-default-no-icon:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.button-default-no-icon:focus {
  outline: none;
}
