.calendar-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.month-year {
  font-family: var(--title-font);
  font-size: 33px;
}

.switch-buttons {
  display: flex;
  gap: 0.5em;
}

.switch-buttons img {
  border: 1px solid var(--neutral-white-200);
  padding: 0.5em;
}

.switch-buttons img:hover,
.switch-buttons img:active {
  border: 1px solid var(--green-primary-500);
}

.days-group {
  display: flex;
  flex-wrap: wrap;
}

.days-names {
  display: flex;
  padding: 1em 0;
  border-bottom: 1px solid grey;
  font-family: var(--paragraph-font);
}

.days-names > div {
  width: 14%;
  display: flex;
  justify-content: center;
}

.days-group .day {
  color: grey;
  display: flex;
  flex-direction: column;
  width: 14%;
  font-family: var(--paragraph-font);
  align-items: center;
  height: 169px;
  justify-content: space-between;
  padding: 0.5em 0;
}

.days-group .day.current-month {
  color: var(--neutral-white-200);
}

.days-group .day.active {
  border: 1px solid var(--green-primary-500);
}

.days-group .day .button-group {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
}

.days-group .day .button-square {
  height: fit-content;
}

.diagram .chart {
  background-image: url(../assets/img/chart-grid-background.png),
    linear-gradient(170deg, transparent 40%, var(--main-green-color) 200%);
  min-height: 200px;
}
