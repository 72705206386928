.dashboard {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "header header"
    "sidebar content"
    "footer footer";
  min-height: 100vh;
}

.header {
  grid-area: header;
  /* background: #f0f0f0; */
  padding: 10px;
  text-align: center;
}

.sidebar {
  grid-area: sidebar;
  background: #ddd;
  padding: 70px 29px 29px 29px;
  display: none; /* Hidden by default */
  /* width: 200px; */
  min-width: var(--desktop-siderbar-min-width);
  height: 100%;
  background: var(--neutral-black-700, #0f0f0f);
  /* background-color: green; */
  /* margin-top: 40px; */
  color: #fff;
  font-family: var(--title-font);
}
.side-navbar {
  display: flex;
  align-items: start;
  justify-self: start;
  flex-direction: column;
  gap: 13px;
}

.links {
  font-size: 29px;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
}

.sidebar-content {
  margin-top: 50px;
}

.content {
  grid-area: content;
  padding: 50px 10px 10px 14px;
  /* background-color: red; */
  background: var(--main-background-gradient);
  height: 100%;
}
.top-margin {
  margin-top: 50px;
}

.footer {
  grid-area: footer;
  background: yellow;
  padding: 10px;
  text-align: center;
  display: block; /* Visible by default */
  font-family: var(--title-font);
  background-image: var(--main-background-gradient);
}
.footer-navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}
.footer-links {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .sidebar {
    display: block;
  }
  .footer {
    display: none;
  }
}
