.app {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.sidebar {
  width: 200px;
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.content {
  flex: 1;
  background-color: #000;
  color: #fff;
  padding: 20px;
}

.measurements-container {
  display: flex;
  flex-wrap: wrap;
}

.measurements-container .daily-measurements,
.measurements-container .weekly-measurements,
.measurements-container .monthly-measurements {
  flex: 1;
  margin: 10px;
  background-color: #111;
  padding: 20px;
  border-radius: 8px;
}

.measurement-input,
.photo-upload {
  margin-bottom: 10px;
  color: var(--neutral-white-200, #f6f6f6);

  /* Headings/h5 */
  font-family: var(--title-font);
}

/* .measurement-input input,
.photo-upload input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
} */

.nutrition-container {
  margin-top: 20px;
  background-color: #111;
  padding: 20px;
  border-radius: 8px;
}

.nutrition-bar {
  margin-bottom: 15px;
}

.nutrition-bar label {
  display: block;
  margin-bottom: 5px;
}

.nutrition-bar input[type="range"] {
  width: 100%;
  color: var(--neutral-white-200, #f6f6f6);

  /* Headings/h5 */
  font-family: var(--title-font);
}

.girovita {
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: none;

  text-align: left;
  color: var(--neutral-white-200, #f6f6f6);

  /* Headings/h5 */
  font-family: var(--title-font);
}

.mainHead {
  color: var(--neutral-white-200, #f6f6f6);

  font-family: var(--title-font);
}

.daily-measurements {
  background-color: #111;
  padding: 30px;
  border-radius: 8px;
  /* color: #fff; */
  max-width: 330px;
  /* margin: auto; */
}

.headingStyling {
  /* font-size: 2em; */
  /* margin-bottom: 20px; */
  margin-top: 20px;
  padding-bottom: 10px;
  color: var(--neutral-white-200, #f6f6f6);

  /* Headings/h5 */
  font-family: var(--title-font);
}

.measurement-input {
  margin-bottom: 20px;
  color: var(--neutral-white-200, #f6f6f6);

  /* Headings/h5 */
  font-family: var(--title-font);
}

.measurement-input label {
  display: block;
  font-size: 1.2em;
  margin-bottom: 8px;
  color: var(--neutral-white-200, #f6f6f6);

  /* Headings/h5 */
  font-family: var(--title-font);
}

.measurement-input input {
  width: 100%;
  padding: 10px;
  background-color: #181818;
  /* border: 0; */
  border-bottom-width: 1px solid #fff;
  border-radius: 4px;

  color: var(--neutral-white-200, #f6f6f6);

  /* Headings/h5 */
  font-family: var(--paragraph-font);
  font-size: 1em;
}

.measurement-input:focus,
input:focus {
  outline: none;
}

.measurement-input input::placeholder {
  color: #888;
}

.help-text {
  font-size: 0.9em;
  color: #888;

  /* Headings/h5 */
  font-family: var(--paragraph-font);
  margin-top: 5px;
}

@media (max-width: 480px) {
  .daily-measurements {
    padding: 15px;
  }

  .daily-measurements h3 {
    font-size: 1.5em;
    color: var(--neutral-white-200, #f6f6f6);

    /* Headings/h5 */
    font-family: var(--title-font);
  }

  .measurement-input input {
    padding: 8px;
    font-size: 0.9em;
    color: var(--neutral-white-200, #f6f6f6);

    /* Headings/h5 */
    font-family: var(--title-font);
  }
}

.showBanner {
  height: 200px;
  background-color: #1a1a1a;
  margin-bottom: 50px;
}

.hunger-level-container {
  /* background-color: #111; */
  /* padding: 15px; */
  border-radius: 8px;
  color: #fff;
  max-width: 400px;
  margin: auto;
}

.hunger-level-container1 {
  /* background-color: #111; */
  /* padding: 15px; */
  border-radius: 8px;
  color: #fff;

  margin: auto;
}

.hunger-label {
  font-size: 1.2em;
  margin-bottom: 10px;
  display: block;
}

.hunger-slider {
  width: 100%;
  /* height: 100px; */
  -webkit-appearance: none;
  appearance: none;
  height: 45px;
  background: linear-gradient(to right, #7fff00 20%, #333 20%);
  border-radius: 5px;
  outline: none;
  opacity: 0.9;
  transition: opacity 0.2s;
}

.hunger-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 25px;
  background: #7fff00;
  cursor: pointer;
  border-radius: 5px;
}

.hunger-slider::-moz-range-thumb {
  width: 20px;
  height: 25px;
  background: #7fff00;
  cursor: pointer;
  border-radius: 5px;
}

.range-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 0.9em;
  color: #888;
}

.range-labels span {
  color: var(--neutral-white-200, #f6f6f6);

  /* Headings/h5 */
  font-family: var(--paragraph-font);
  font-size: 0.9em;
}

.barP {
  height: 50px;
  background-color: #333;
  width: 90%;
  margin-left: 10px;
  transform: skewX(-10deg); /* Skew to create the trapezoid effect */
  /* background: linear-gradient(to right, #7fff00 20%, #333 20%); */
}

.isQ {
  width: 2px;
  height: 50px; /* Adjust the height as needed */
  background-color: #555; /* The color of the line */
  transform: rotate(10deg); /* Rotate the line to make it diagonal */
  margin: 0 auto; /* Center the line horizontally */
  z-index: -9999;
}

.colorBar {
  width: 93px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  background-color: #7fff00; /* The green color */
  transform: skewX(-10deg); /* Skew to create the trapezoid effect */
  /* margin-left: -10px; */
  z-index: 999;
  margin-top: -50px;
  /* margin: 0 auto; Center the shape horizontally */
}
.speedQuality {
  display: "flex";
  width: 70px;
  z-index: 9999;
}

.upload-container {
  margin: 20px 0;
  width: 100%;
  /* text-align: left; */
}

.upload-label {
  font-size: 1.2em;
  color: #fff;
  margin-bottom: 10px;
  display: block;
}

.upload-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #fff;
  /* border-radius: 5px; */
  padding: 10px;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  font-size: 1em;
  width: 100%;
  margin-bottom: 10px;
  font-size: 0.8em;
}

.upload-button:hover {
  background-color: #333;
}

.upload-icon {
  margin-right: 8px; /* Spacing between the icon and text */
  font-size: 1.2em; /* Size of the up arrow icon */
}

.upload-text {
  font-weight: bold;
}

.sleep-hours-container {
  color: #fff;
  font-family: Arial, sans-serif;
  max-width: 300px;
  /* margin: 40px auto; */
}

.sleep-title {
  font-size: 1.5em;
  margin-bottom: 20px;
  font-family: var(--title-font);
  color: var(--neutral-white-200, #f6f6f6);

  /* Headings/h5 */
}

.sleep-option {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.sleep-option input[type="radio"] {
  display: none;
}

.sleep-option label {
  font-size: 1.2em;
  cursor: pointer;
  display: flex;
  font-family: var(--paragraph-font);
  color: var(--neutral-white-200, #f6f6f6);
  align-items: center;
}

.custom-radio {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.custom-radio::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  background-color: transparent;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.2s ease;
}

.sleep-option input[type="radio"]:checked + label .custom-radio {
  border-color: #7fff00;
}

.sleep-option input[type="radio"]:checked + label .custom-radio::before {
  /* background-color: #7fff00; */
  content: "✔";
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.sprateColorsStep {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.sleep-option input[type="checkbox"] {
  display: none; /* Hide the default checkbox */
}

.sleep-option label {
  font-size: 1.2em;
  cursor: pointer;
  display: flex;
  font-family: var(--paragraph-font);
  color: var(--neutral-white-200, #f6f6f6);
  align-items: center;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 5px; /* Make it a square checkbox */
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.custom-checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  background-color: transparent;
  transform: translate(-50%, -50%);
  transition: background-color 0.2s ease;
}

.sleep-option input[type="checkbox"]:checked + label .custom-checkbox {
  border-color: #7fff00;
}

.sleep-option input[type="checkbox"]:checked + label .custom-checkbox::before {
  content: "✔"; /* Show tick mark */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
