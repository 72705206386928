:host,
app-pre-allenamento-giorno-on {
  display: flex;
  width: 100%;
}

.link-video-circuito {
  width: auto;
  display: inline-block;
  padding: 0;
  clip-path: none;
}

.riposo-img {
  max-width: 100%;
  width: 100%;
}

.head-info,
.riposo.text {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 0.5em;
  margin: 1em 0;
  width: 100%;
}

.head-info .title,
.riposo.text .title {
  color: var(--neutral-white-200, #f6f6f6);
  font-family: var(--title-font);
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.riposo.text .subtitle {
  color: var(--neutral-white-200, #f6f6f6);
  font-family: var(--title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.info-container {
  color: var(--primary-300, #d0feb7);

  /* Buttons */
  font-family: var(--title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  margin-bottom: 1em;
}

.info-container > div {
  display: flex;
  gap: 0.5em;
}

.salta {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.salta .button {
  margin-top: var(--margin-to-avoid-header-overlap);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  margin-right: 1em;
  padding: 0.5em;
  color: var(--primary-500, #a0fc6e);
  font-family: var(--title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.video-player {
  position: relative;
}

.video-player img,
.video-player video {
  width: 100vw;
  height: 85vh;
}

#warmup-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  background: #171717;
  min-height: 100%;
}
#warmup-overlay .video-player {
  max-height: calc(100vh - 100px);
}

.timer {
  background-image: url(../../assets/img/desktop-splash-background.jpg);
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  z-index: 100;
}

.timer .title {
  color: var(--neutral-white-200, #f6f6f6);
  text-align: center;
  font-family: var(--title-font);
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.timer .clock {
  color: var(--primary-500, #a0fc6e);
  font-family: var(--title-font);
  font-size: 128px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.feedback {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
}

.feedback .title {
  font-size: 54px;
  text-align: -webkit-center;
}

.feedback .progress-bar {
  margin-bottom: 3em;
  padding: 1em;
}

.feedback .progress-bar,
.feedback a {
  width: 80%;
  max-width: var(--slim-desktop-width);
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5em;
  /* background-color: red; */
  width: 90%;
  margin: 0 auto;
}

.title-container {
  /* background-color: blue; */
  margin-bottom: 20px;
}
.item-title {
  color: var(--neutral-white-200, #f6f6f6);
  font-family: var(--title-font);
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 30px;
  background: var(--main-background-gradient);
  color: white;
  align-items: center;
  min-height: 90px;
  padding: 0px 16px;
}
.item-container {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-self: center;
}
.item-img {
  background-color: #1e1e1e;
  width: 50px;
  height: 100%;
}

.description {
  margin-bottom: 5px;
}

.para {
  font-size: 18px;
  font-weight: 400;
  font-family: var(--paragraph-font);
  line-height: normal;
  width: 150px;
}

.allenamento-card-middle-btn {
  padding: 16px;
}

.allenamento-card-middle-description {
  font-size: 14px;
  font-weight: 300;
  color: gray;
}

.progress-container {
  text-align: center;

  color: #ffffff;
}

.progress-text {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.progress-bar {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.progress-segment {
  width: 50px;
  height: 4px;
  background-color: #ffffff;
  margin: 0 5px;
  /* border-radius: 5px; */
  clip-path: polygon(1em 0%, 100% 0%, calc(100% - 1em) 100%, 0% 100%);
}

.progress-segment.completed {
  background-color: #a0fc6e; /* Completed color */
}

.progress-week {
  font-size: 1em;
}

@media screen and (min-width: 768px) {
  .settimana-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5em;
  }

  .step-indicator {
    margin: 0 30%;
  }

  .measurements {
    flex: auto;
  }

  .button-default-no-icon {
    width: 95%;
  }

  .info-container {
    flex-direction: row;
  }

  .card-allenamento {
    width: 100%;
  }

  .card-allenamento .desktop-container {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 columns for screens 768px and wider */
  }
}

@media screen and (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* 3 columns for screens 1024px and wider */
  }
}
.grid-item {
  margin-bottom: 2em;
}

.img-cover {
  height: 32px;
  width: 48px;
  overflow: hidden;
}

.img-cover img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.grid-item.disabled {
  opacity: 0.5;
  pointer-events: none;
}
