.modal-overlayy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Equivalent to #000000CC */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999 !important;
  flex-direction: column;
  text-align: center;
}

.modal-content {
  color: white;
  background: var(--neutral-black-700, #0f0f0f);
  border-radius: 0.375rem;
  padding: 20px 20px;
  position: relative;
  width: 25%;
  min-width: 400px;
  position: relative;
}
.modal-content-valid {
  color: white;
  background: var(--neutral-black-700, #0f0f0f);
  border-radius: 0.375rem;
  padding: 20px 20px;
  position: relative;
  width: 40%;
  min-width: 400px;
  position: relative;
}

.showData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-bottom: 5px;
}
.scroll-container {
  max-height: 350px;
  overflow-y: auto;
  padding-right: 10px;
}
.scroll-container::-webkit-scrollbar {
  width: 8px;
}
.scroll-container::-webkit-scrollbar-track {
  background: transparent;
}
.scroll-container::-webkit-scrollbar-thumb {
  background-color: #3b8004;
  border-radius: 10px;
}

.btnData {
  border: 1px solid #a0fc6e;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #a0fc6e;
  background: transparent;
  cursor: pointer;
}

.modal-content p {
  text-align: left;
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--title-font);
}
.modal-title {
  text-align: left;
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--title-font);
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-title {
  font-size: 1.5rem; /* Equivalent to text-xl */
  font-weight: 500; /* Equivalent to font-medium */
  margin-bottom: 0.75rem; /* Equivalent to pb-3 */
}

.modal-title-md {
  font-size: 1.5rem; /* Equivalent to md:text-[24px] */
}

.form-div {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
}

.form-div label {
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 14px;
  font-family: var(--title-font);
}

.form-div input {
  outline: none;
  border: none;
  border-bottom: 1px solid #fff !important;
  background-color: transparent !important;
  font-family: var(--paragraph-font);
  font-size: 20px;
  padding-bottom: 5px;
  color: #8f8f8f;
  width: 100%;
}

.form-div.picker {
}

.form-div input:active {
  background-color: transparent;
}

.form-div select {
  outline: none;
  border: none;
  border-bottom: 1px solid #fff;
  font-family: var(--paragraph-font);
  width: 107px;
  border: 1px solid var(--green-primary-500);
  background-color: transparent;
  color: #fff;
  font-family: var(--title-font);
  padding: 8px 16px;
  margin-bottom: 10px;
}

.form-div select option {
  background-color: #333;
}

.assign {
  text-transform: uppercase;
  font-size: 29px;
  margin-bottom: 10px;
  font-family: var(--title-font);
}

.note-input {
  text-align: left;
  font-size: 24px;
  font-family: var(--title-font);
  padding-bottom: 20px;
}
.note-input input {
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid gray;
  color: #fff;
  margin-top: 20px;
}
.modal-btn {
  height: 40px;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral-black-500);
  background-color: var(--main-green-color);
  font-family: var(--button-font);
  text-decoration: none;
  clip-path: polygon(1em 0%, 100% 0%, calc(100% - 1em) 100%, 0% 100%);
}
.modal-content-task-body {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: start;
}
@media (max-width: 600px) {
  .modal-content {
    color: white;
    background: var(--neutral-black-700, #0f0f0f);
    border-radius: 0.375rem;
    padding: 10px 20px;
    position: relative;
    width: 25%;
    min-width: 300px;
    position: relative;
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  .modal-content {
    color: white;
    background: var(--neutral-black-700, #0f0f0f);
    border-radius: 0.375rem;
    padding: 10px 20px;
    position: relative;
    width: 25%;
    min-width: 300px;
    position: relative;
  }
}
@media (max-width: 1025px) {
  .modal-content {
    color: white;
    background: var(--neutral-black-700, #0f0f0f);
    border-radius: 0.375rem;
    padding: 20px;
    position: relative;
    width: 25%;
    min-width: 400px;
    position: relative;
  }
}

.error-messages {
  color: red;
  font-size: 8px;
  margin-top: 5px;
}

.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid var(--main-green-color);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
