.register-content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  /* 
  margin: 0;
  max-width: var(--slim-desktop-width); */
  /* background-color: red; */
  /* max-width: 1000x; */
  margin: 0 auto;
  width: 500px;
  padding: 1rem;
}

.register-content > div {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

footer {
  display: none;
}

form {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.oppure {
  align-self: center;
}

.margin-top {
  margin: 5px 0px;
}

.headline {
  color: var(--neutral-white-200);
  text-align: center;
  font-family: var(--title-font);
  font-size: 51px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.text-input .title,
.button-default-no-icon,
.button-default-no-icon.outline {
  text-transform: uppercase;
}

.social-login-buttons {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 1em 0;
}

/*Dropdown */
.text-input select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.text-input select:focus {
  border-color: #007bff;
  outline: none;
}

.text-input select::-ms-expand {
  display: none;
}

.text-input select {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEwIiBoZWlnaHQ9IjExMCIgdmlld0JveD0iMCAwIDExMCAxMTAiIHhtbG5zPSJodHRwOi8vMTk5OS53My5vcmcvMjAwMC9zdmciIHhtbG5zPSJodHRwOi8vMTk5OS53My5vcmcvMjAwMC9zdmciIHN2Zy1saW5rLW1vZGU9InB1cnBsZSI+PHBhdGggZD0iTTAsMCAwLDEwMCAxMTAiIHN0cm9rZT0iIzAwN2JmZiIvPjwvc3ZnPg==")
    no-repeat right 10px center;
  background-size: 12px 12px;
  padding-right: 35px;
  color: white;
}

.text-input select:hover {
  border-color: #0056b3;
}

.text-input select:focus {
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.text-input select option {
  font-size: 16px;
  background-color: black;
  border: none;
  outline: none;
}

.text-input select option:hover {
  background-color: #f1f1f1;
}
