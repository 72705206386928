.notes {
  /* background-color: green; */
  background: var(--main-background-gradient);
  padding: 10px;
  border-radius: 8px;
}

.content-right-sidebar .notes {
  margin-left: 30px;
}

.content-right-sidebar .notes > .title {
  font-size: 29px;
}

.content-right-sidebar .notes .card > .title {
  font-size: 23px;
  font-family: var(--paragraph-font);
  display: flex;
  justify-content: space-between;
}

.content-right-sidebar .notes .card > .description {
  font-size: 18px;
  font-family: var(--paragraph-font);
}

.description input {
  border: none;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: 1em;
  color: white;
  width: 50%;
}

.description p {
  width: 80%;
}

.description button {
  border: none;
  background-color: #007bff;
  color: white;
  padding: 5px 5px;
  font-size: 0.9em;
  cursor: pointer;
  border-radius: 3px;
}

.description button:not(:last-child) {
  margin-right: 5px;
}

.description button:hover {
  background-color: #0056b3;
}

.notes-group .notes .card {
  /* background: var(--main-background-gradient); */
  display: flex;
  flex-direction: column;
  padding: 1em 0.5em;
  gap: 0.5em;
  outline: none;
  border: none;
  border-bottom: 1px solid #565656;
  border-radius: 0px;
}
.note-margin {
  margin-top: 20px;
}

.notes-group .notes .card > .title {
  font-size: 23px;
  font-family: var(--paragraph-font);
  display: flex;
  justify-content: space-between;
}

.notes-group .notes .card > .description {
  font-size: 18px;
  font-family: var(--paragraph-font);
}

.admin-notes-width {
  /* background-color: red; */
  width: 100%;
}

.admin-notes-width .card {
  background-color: transparent;
  color: white;
}

.admin-notes-width .noteButton {
  margin-top: 30px;
}

@media only screen and (min-width: 768px) {
  .admin-notes-width {
    max-width: 400px;
  }
}
