body {
  /* background-image: url("../../../public/assets/img/desktop-splash-background.jpg"); */
  background-size: cover;
}

/* Remove any navigation bar */

footer,
.navigation-bar {
  /* display: none; */
}

.content-home {
  display: flex;
  height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background: transparent;
  width: var(--slim-desktop-width);
  padding: 1rem;
}

.welcome {
  max-width: var(--slim-desktop-width);
}

.welcome > .title {
  text-align: center;
  font-family: var(--title-font);
  font-size: 51px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.welcome > .subtitle {
  text-align: center;
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login-register {
  width: 100%;
}

.disclaimer {
  color: var(--neutral-white-500);
  text-align: center;

  /* Labels */
  font-family: var(--paragraph-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.onboarding-end-video-player {
  position: fixed;
  width: 100%;
  height: 100%;
  top: var(--margin-to-avoid-header-overlap);
  left: 0;
  /* background: url(./assets/img/vi) center; */
}
