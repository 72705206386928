app-user-editor-ricetta,
:host {
  width: 100%;
}

content {
  width: calc(100% - var(--content-left-margin-to-avoid-sidebar));
  box-sizing: border-box;
}

.nutrition-sources-gap {
  /* background-color: red; */
  margin-left: 20px;
}
.nutrition-sources .inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  /*padding: 20px 0;*/
}

.nutrition-sources .inner .fonte {
  padding: 20px 20px 0 0;
}

.deactive {
  pointer-events: none;
  opacity: 0.5;
}

.font-key {
  font-family: var(--paragraph-font);
}

.title-inner {
  font-family: var(--title-font);
  font-size: 33px;
}
