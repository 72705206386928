.welcome .date {
  color: var(--neutral-white-200, #f6f6f6);

  /* Paragraphs/p4 */
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.welcome .name {
  color: var(--neutral-white-200, #f6f6f6);

  /* Headings/h5 */
  font-family: var(--title-font);
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content > .welcome,
.content > .allenamento,
.content > .nutrizione,
.content > .progressi {
  width: 60%;
}

.content > .nutrizione,
.content > .progressi {
  gap: 0.5em;
}

.content > section > a {
  margin-top: 1em;
}

.box-group {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.progressi {
  gap: 1em;
}

.measurements {
  border-radius: 8px;
  border: 1px solid var(--neutral-black-400, #565656);
  padding: 0.5em;
}

.measurements img {
  max-width: 230px;
}

.measurements > * {
  margin-bottom: 0.1em;
}

/* label.input-container {
  margin: 0.5em 0;
} */

.progress-box {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  gap: 2em;
}

@media screen and (min-width: 768px) {
  .measurements {
    flex: auto;
  }

  .button-default-no-icon {
    width: 95%;
  }
}
