.tab-mar-bottom {
  margin-bottom: 50px;
}
.custom-tabs {
  display: flex;
  width: 100%;
  justify-content: space-around;
  font-family: var(--paragraph-font);
  margin-top: 30px;
}

.custom-tab {
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  color: #8f8f8f;
  font-size: 18px;
  position: relative;
  width: 100%;
}
.w-full-tab {
  width: 100%;
}

.custom-tab.active {
  color: white;
}

.custom-tab.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 2px;
  width: 100%;
  background-color: var(--main-green-color);
}

.modal-btn-font {
  font-size: 12px;
}

.bg-color {
  background-color: #111;
  color: #fff;
}

.bg-none {
  /* background-color: green; */
  /* background: var(--main-background-gradient); */
  background: var(--neutral-black-600);
  color: #fff;
}

.admin-bottom-container a {
  color: #a0fc6e;
  background-color: transparent;
  font-size: 16px;

  text-transform: uppercase;
  font-family: var(--title-font);
}

@font-face {
  font-family: "Familjen Grotesk Regular";
  src: url("../../../assets/fonts/FamiljenGrotesk-Variable.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bebas Neue Regular";
  src: url("../../../assets/fonts/BebasNeue-Regular.ttf") format("truetype"),
    url("../../../assets/fonts/BebasNeue-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --title-font: "Bebas Neue Regular", sans-serif;
  --button-font: "Bebas Neue Regular", sans-serif;
  --paragraph-font: "Familjen Grotesk Regular", sans-serif;
}

/* Header.css */
.header {
  background: var(--neutral-black-800, #080808);
  display: flex;
  align-items: center;
}

.logo {
  padding: 10px;
  margin-left: 30px;
  width: 35px;
  height: 35px;
}

/* Sidebar.css */
.sidebar {
  height: 100%;
  width: 200px;
  background-color: #0f0f0f;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: 100px;
  /* padding-left: 40px; */
}

.sidebar-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  gap: 5px;
}

.sidebar-item img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  filter: invert(100%) brightness(10) saturate(10%) brightness(4);
}

.sidebar-item span {
  font-size: 30px;
  font-family: var(--title-font);
}

.sidebar-item.active {
  color: #a0fc6e;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    padding: 10px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #0f0f0f;
    z-index: 100;
    position: fixed;
  }

  .sidebar-item {
    margin: 0;
    padding: 10px;
  }

  .sidebar-item span {
    font-size: 12px;
  }
}

/* Main.css */
.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container {
  display: flex;
  flex: 1;
}

.main {
  flex: 1;
}

.topBar {
  margin-top: 20px;
  background-color: transparent;
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 2px solid #a0fc6e;
}
.topBar .searchIcon {
  cursor: pointer;
}

.topBar img {
  /* margin-left: 20px;
  margin-right: 10px; */
  width: 24px;
  height: 24px;
}

.topBar span {
  margin-left: 10px;
  font-size: 35px;
  font-family: var(--title-font);
}

.table-top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex: 1;
  display: flex;
}

.entries-search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-left: 30px;
  margin-right: 30px;
}

.entries-dropdown {
  margin-right: 10px;
}

.search-filter input[type="text"] {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 150px;
  background-color: transparent;
  font-family: var(--paragraph-font);
}

.entries-dropdown select {
  background: #222222;
  width: 50px;
  color: white;
  border-radius: 2px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

label {
  font-family: var(--paragraph-font);
}

.mainContent {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/* Calendrio */
.calendrioContainer {
  margin-bottom: 100px;
}
table {
  width: 93%;
  background: var(--neutral-black-800, #080808);
  /* margin-left: 30px; */
  border-spacing: 0;
  font-family: var(--paragraph-font);
}

.custom-table th {
  border: 1px solid grey;
  padding: 10px;
  text-align: left;
}

.custom-table td label {
  background-color: var(--blue-secondary-700);
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 10px;
}

.custom-table td {
  font-family: var(--paragraph-font);
  font-size: 15px;
  color: var(--main-text-color);
  padding: 10px;
}

.headerCalendrio {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-top: 30px;
}

.backArrow {
  margin-right: 20px;
}

.titleText {
  font-size: 35px;
  font-family: var(--title-font);
}
.title-para {
  font-size: 35px;
  font-family: var(--title-font);
  border-bottom: 1px solid #565656;
}

.button-default-no-icon img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.button-default-no-icon p {
  margin-top: 20px;
}

.calendar-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 70%;
  margin-left: 30px;
}

.admin-calendar-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.horiLine {
  border-bottom: 1px solid #a0fc6e;
  width: 60%;
  margin-left: 30px;
  margin-bottom: 40px;
}

.calendar-group > div {
  flex-basis: 100%;
  padding: 1em;
  border: 1px solid transparent;
}

.calendar-group > div > a {
  margin-top: 1em;
}

.calendar-group > div > .title {
  font-family: var(--title-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.calendar-group .green-border {
  border: 1px solid var(--green-primary-500);
  border-radius: 8px;
}

.calendar-group .task-editor {
  display: flex;
  flex-direction: column;
  gap: 1em;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding-top: 1em;
  padding-bottom: 1em;
}

.calendar-group .task-body {
  display: flex;
  gap: 1em;
}

.admin-calendar-group > div {
  flex-basis: 100%;
  padding: 1em;
  border: 1px solid transparent;
}

/* .header-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
  } */

.header-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}

.header-icon {
  margin-left: 8px; /* Example margin */
}

.header-icon {
  height: 20px;
  width: 20px;
  filter: invert(100%) sepia(100%) brightness(100%) contrast(100%);
}
.admin-calendar-group > div > a {
  margin-top: 1em;
}

.admin-calendar-group > div > .title {
  font-family: var(--title-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.admin-calendar-group .green-border {
  border: 1px solid var(--green-primary-500);
  border-radius: 8px;
}

.admin-calendar-group .task-editor {
  display: flex;
  flex-direction: column;
  gap: 1em;
  /* border-top: 1px solid #565656; */
  border-bottom: 1px solid #565656;
  padding-top: 1em;
  padding-bottom: 1em;
}

.admin-calendar-group .task-body {
  display: flex;
  gap: 1em;
}

.task-editor .task-body {
  font-family: var(--title-font);
}

.task-editor label {
  font-family: var(--paragraph-font);
  margin-top: 5px;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .calendar-group > div {
    flex-basis: 30%;
  }
}

@media only screen and (min-width: 768px) {
  .admin-calendar-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    /* margin-left: 30px; */
  }
}

.content-visibility {
  /* background-color: red; */
  display: none;
  visibility: hidden;
}

.admin-coach-mobile {
  background-color: block;
}

@media only screen and (min-width: 768px) {
  .content-visibility {
    /* background-color: red; */
    display: block;
    visibility: visible;
  }

  .admin-coach-mobile {
    display: none;
  }
}

.admin-coach-select {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 5px;
  margin: 20px 0px;
  text-transform: uppercase;
}

.admin-coach-select select {
  background-color: transparent;
  padding: 5px 20px;
  color: #fff;
  border: 1px solid #a0fc6e;
  text-transform: uppercase;
  font-size: 16px;
  font-family: var(--title-font);
}

.admin-coach-select select option {
  background-color: #333;
  text-transform: uppercase;
}

.admin-coach-select select:focus {
  outline: none;
}

.admin-coach-mobile-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  background: var(--main-background-gradient);
  border-radius: 8px;
  padding: 20px;
}
.card-flex {
  display: flex;
  gap: 20px;
  align-items: center;
}

.admin-coach-mobile-flex span {
  color: gray;
}

.mobile-card-para {
  font-family: var(--title-font);
  font-size: 25px;
}
.mobile-tags {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mobile-tags p {
  background-color: blue;
  color: white;
  padding: 3px 20px;
  font-size: 15px;
  border-radius: 8px;
}

.sub---title {
  font-size: 29px;
  border-bottom: 1px solid #565656;
  font-family: var(--title-font);
}
.sub---title-no-border {
  font-size: 29px;
  font-family: var(--title-font);
}

.mt-side-bar {
  margin-top: 30px;
}

.content-right-sidebar .card {
  background: var(--main-background-gradient);
  /* background: transparent; */
  display: flex;
  flex-direction: column;
  padding: 1em 0.5em;
  gap: 1em;
  cursor: pointer;
  /* width: 30%; */
}

/* @media (max-width: 768px) {
  
} */

.content-right-sidebar .card .top,
.content-right-sidebar .card .bottom {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.content-right-sidebar .card .title {
  color: var(--neutral-white-200, #f6f6f6);
  font-family: var(--title-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content-right-sidebar .card .description {
  color: var(--neutral-white-200, #f6f6f6);
  /* font-family: var(--paragraph-font); */
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
}
.content-right-sidebar .card .description .nome {
  font-size: 18px;
  font-family: var(--paragraph-font);
}
.ddmm {
  font-size: 16px;
  font-family: var(--title-font);
}

.content-right-sidebar .card .bottom {
  display: flex;
  justify-content: space-between;
}

.content-right-sidebar .task-editor {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.table p {
  font-size: 15px;
  font-family: var(--paragraph-font);
  margin-left: 30px;
}
.bottom-flex {
  display: flex;
  gap: 30px;
}

.bottomTextHome {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottomRightText {
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  align-items: center;
}

.bottomRightCount {
  border: 1px solid grey;
  justify-content: center;
  width: 40px;
  height: 25px;
  text-align: center;
  padding-top: 3px;
}

.bottomRightText p {
  color: gray;
}

.table-link {
  text-decoration: none;
  color: inherit;
}

.total-group {
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
}

/* .total-group > div {
  max-width: 45%;
} */

.coach-calendar {
  margin-top: 30px;
}

/* CustomModal */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal {
  background: black;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.custom-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #a0fc6e;
}

.custom-modal-body {
  padding: 20px 0;
}

.custom-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.text {
  color: white;
}

.customButtonSend {
  background-color: var(--blue-secondary-700);
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.customButtonClose {
  background-color: white;
  /* border: none; */
  outline: none;
  padding: 10px;
  border-radius: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.videoModal {
  width: 80%;
  height: 90vh;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
  color: #a0fc6e;
}

.modal-actions {
  margin-top: 20px;
}

.save-button,
.cancel-button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

.save-button {
  background-color: #a0fc6e;
  color: white;
}

.cancel-button {
  background-color: gray;
  color: #fff;
}

.recipeText {
  font-size: 20px;
  font-family: var(--paragraph-font);
  color: #a0fc6e;
}

/* Loader */
header {
  /* height: var(--mobile-header-height); */
  height: 50px;
  background-image: var(--main-background-gradient);
  display: flex;
  flex-direction: row;
  padding: var(--mobile-header-padding);
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

header .header-container {
  width: 100%;
  max-width: var(--max-site-width);
  margin-left: auto;
  margin-right: auto;
}

header .logo-icon {
  position: relative;
  width: 19px;
  height: 35px;
}
.admin-future {
  background: var(--main-background-gradient);
  border-radius: 8px;
}
.admin-completed {
  background: var(--main-background-gradient);
  border-radius: 8px;
}

.admin-custom-table {
  border-spacing: 0 10px; /* Adds vertical spacing between rows */
  border-collapse: separate; /* Ensures border-spacing takes effect */
  background-color: transparent;
}

.admin-custom-table th {
  padding: 10px;
  text-align: left;
  background-color: #111;
  color: #fff;
}

.border-right {
  border-right: 1px solid grey;
}
.admin-custom-table td label {
  background-color: var(--blue-secondary-700);
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 10px;
  line-height: 15px;
}
.tagLabelParent {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.admin-custom-table td {
  font-family: var(--paragraph-font);
  font-size: 18px;
  padding: 10px;
}

.admin-custom-table .bg-color {
  background-color: #111;
  color: #fff;
}

.admin-custom-table .bg-none {
  /* background-color: green; */
  /* background: var(--main-background-gradient); */
  background: var(--neutral-black-600);
  color: #fff;
}

.admin-bottom-button {
  display: flex;
  margin-left: 30px;
  align-items: center;
  justify-self: center;
  gap: 10px;
  font-family: var(--paragraph-font);
}

.admin-bottom-container a {
  color: #a0fc6e;
  font-family: var(--title-font);
  background-color: transparent;
  text-transform: uppercase;
  font-size: 16px;
}

.admin-bottom-container a img {
  filter: invert(46%) sepia(62%) saturate(5740%) hue-rotate(87deg)
    brightness(102%) contrast(101%);

  height: 16px;
  width: 16px;
}

.admin-bottom-button img {
  /* background-color: red; */
  height: 20px;
  width: 20px;
}

.admin-coach-step-two-container {
  display: flex;
  gap: 40px;
  align-items: center;
  padding: 10px 0px;
}

.admin-coach-step-two-container-atleti {
  display: flex;
  gap: 40px;
  background-color: #000;
  margin-left: -14px;
  align-items: center;
  padding-left: 20px;
  /* padding: 10px; */
  padding-top: 10px;
  padding-bottom: 10px;
}
.avatar-container {
  width: 70px;
  height: 70px;
}
.avatar-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.name {
  font-size: 30px;
  font-weight: 300;
  color: #fff;
  font-family: var(--title-font);

  padding-top: 20px;
}
.attivata {
  cursor: pointer;
}
.span-green {
  color: var(--main-green-color);
}
.top-nav span {
  margin-left: 7px;
  cursor: pointer;
}
.top-nav {
  font-size: 1.2rem;
  font-weight: 300;
  color: #fff;
  font-family: var(--title-font);

  padding-top: 20px;
}

.dark .name {
  color: #ffffff;
}

.admin-coach-step-card-flex {
  display: flex;
  gap: 20px;
}
.admin-coach-step-card-title {
  font-size: 51px;
  font-weight: 400;
  font-family: var(--title-font);
}
.admin-coach-step-card {
  /* background-color: purple; */
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background: var(--main-background-gradient);
  font-family: var(--title-font);
}

.step-card-sub-heading {
  text-transform: uppercase;
  font-size: 25px;
  font-family: var(--title-font);
}

.admin-coach-step-card-flex {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.admin-coach-step-card-flex span {
  color: #41b104;
  font-family: var(--paragraph-font);
  font-size: 18px;
}

.rating {
  padding: 20px;
  background: var(--main-background-gradient);
  border-radius: 8px;
}
.rating-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.green-color {
  background-color: green;
  width: 13px;
  height: 13px;
}
.red-color {
  background-color: red;
  width: 13px;
  height: 13px;
}

.rating-card-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.rating-card-flex-para {
  font-family: var(--paragraph-font);
  font-size: 18px;
}

.custom-button {
  color: white; /* text-white */
  background-color: transparent; /* bg-transparent */
  font-weight: 500; /* font-medium */
  border-width: 1px; /* border-2 */
  border-color: #f6f6f6; /* border-[#F6F6F6] */
  font-size: 0.875rem; /* text-sm (approx. 14px) */
  margin: 0px 10px;
  padding: 10px;
}
.activeBtn {
  border: 1px solid #a0fc6e;
}
.customers {
  /* background-color: aqua; */
  background: var(--main-background-gradient);
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customers-cards {
  padding: 20px;
  /* background-color: greenyellow; */
  font-family: var(--title-font);
  font-size: 23px;
  /* width: 70%; */
  width: 30%;
  border-right: 1px solid #fff;
}

.customers-cards p {
  font-size: 25px;
}

.customers-cards span {
  font-size: 50px;
}
.line {
  height: 100%;
  width: 1px;
}

.hours {
  background: var(--main-background-gradient);
  border-radius: 8px;
}
.hours-card {
  padding: 20px;
}

.line-horizontal {
  background-color: gray;
  width: 90%;
  height: 1px;
  margin: auto;
}
/* Training */
.Training-container {
  border-bottom: 2px solid #565656;
  padding-bottom: 2rem; /* Equivalent to pb-8 */
  font-family: var(--title-font);
  border: 1px solid gray;
  border-radius: 8px;
}
.Training-container-margin {
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
  margin-right: 10px;
}

.Training-container .header-title {
  color: white;
  padding-left: 1.25rem; /* Equivalent to px-5 */
  /* padding-top: 1.25rem; Equivalent to pt-5 */
  font-size: 1.875rem; /* Equivalent to text-3xl */
  margin-top: 20px;
  font-size: 29px;
  font-weight: 400;
  font-family: var(--title-font);
  /* margin: 0px; */
  margin-bottom: 0px;
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.subheader-title {
  color: white;
  padding-left: 1.25rem; /* Equivalent to px-5 */
  font-size: 24px; /* Equivalent to text-3xl */
  margin-left: 0.25rem; /* Equivalent to ml-1 */
  font-weight: 400;
  font-family: var(--title-font);
}

.date-duration {
  margin-left: auto;
  margin-right: 5px;
  font-size: 16px;
  display: flex;
  gap: 20px;
  align-items: baseline;
}

.duration-text {
  color: white;
  padding-left: 1.25rem; /* Equivalent to px-5 */
  font-size: 18px; /* Equivalent to text-xl */
  margin-left: 0.25rem; /* Equivalent to ml-1 */
  margin-top: 0.5rem; /* Equivalent to mt-2 */
  font-family: var(--title-font);
  font-weight: 400;
}

.date-input {
  width: 11%; /* Equivalent to w-[11%] */
  margin-bottom: 0.5rem; /* Equivalent to mb-2 */
  font-family: var(--title-font);
}

@media (min-width: 1024px) {
  /* Equivalent to lg: */
  .date-input {
    width: 6%; /* Equivalent to lg:w-[6%] */
  }
}

.input-field {
  background-color: #565656;
  border: 0;
  color: white;
  font-size: 18px; /* Equivalent to text-sm */
  border-radius: 4px; /* Equivalent to rounded-md */
  /* padding: 0.375rem; Equivalent to p-1.5 */
  padding: 0.375rem;
  width: 100%;
  font-family: var(--paragraph-font);
  placeholder-color: white;
  box-shadow: none; /* Equivalent to focus:ring-0 */
  margin-bottom: 10px;
}

.separator {
  color: white;
  padding: 0.25rem 0.5rem; /* Equivalent to px-2 py-1 */
  margin-bottom: 0.5rem; /* Equivalent to mb-2 */
}

/* Shared styles for items */
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  /* background-color: red; */
}
.inp_box {
  padding: 5px 20px;
}

.item.justify {
  justify-content: space-between;
  /* background-color: green; */
  padding: 0px 20px;
}
.item-margin {
  margin-left: 20px;
}

.icon {
  margin-top: 0.25rem; /* Equivalent to mt-1 */
}

.text {
  color: white;
  font-size: 1.5rem; /* Equivalent to text-2xl */
}

.secondary {
  margin-left: 0.75rem; /* Equivalent to ml-3 */
  margin-top: 1rem; /* Equivalent to mt-4 */
}

.gradient-box {
  background: linear-gradient(to bottom, #1e1e1e, #000000);
  width: 4rem; /* Equivalent to w-16 */
  height: 4rem; /* Equivalent to h-16 */
  margin-left: 0.75rem; /* Equivalent to ml-3 */
}
.gradient-box-image {
  background: linear-gradient(to bottom, #1e1e1e, #000000);
  width: 4rem; /* Equivalent to w-16 */
  height: 4rem; /* Equivalent to h-16 */
  margin-left: 0.75rem; /* Equivalent to ml-3 */
}
.gradient-box-image input {
  width: 4rem;
  height: 4rem;
  opacity: 0;
}

.input-group {
  width: 100%; /* Equivalent to w-[50%] */
  margin-top: 0.75rem; /* Equivalent to mt-3 */
}

.input-field.description {
  text-align: start;
  height: 100px;
  resize: none;
  border: none;
  outline: none;
}

/* .select-container {
  margin-left: 0.75rem; 
  margin-left: 1.25rem; 
} */

.select-box {
  background-color: #111;
  border: 2px solid #a0fc6e;
  color: white;
  padding: 0.5rem;
  font-size: 0.875rem; /* Equivalent to text-sm */
}

.activity {
  /* background-color: blue; */
}
.row-activity {
  background-color: tomato;
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.row-activity-select-container {
  background-color: red;
}

.row-activity-select {
  background-color: #565656;
  border: 0;
  color: white;
  text-align: center;
  font-size: 0.875rem; /* Equivalent to text-sm */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  padding: 0.375rem; /* Equivalent to p-1.5 */
  width: 100%;
  box-shadow: none; /* Equivalent to focus:ring-0 */
  placeholder-color: white;
}

.foodplan-select {
  font-family: var(--paragraph-font);
  background-color: #565656;
  border: 0;
  color: white;
  text-align: center;
  font-size: 18px; /* Equivalent to text-sm */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  padding: 0.375rem; /* Equivalent to p-1.5 */
  width: 161px;
  box-shadow: none; /* Equivalent to focus:ring-0 */
  placeholder-color: white;
}

.row-activity-select.small {
  width: 30%; /* Equivalent to w-[9%] */
}
.row-activity-select.medium {
  width: 45%; /* Equivalent to w-[15%] */
  margin-left: 2.5rem; /* Equivalent to ml-10 */
}

.input-container-activity.small {
  width: 45px;
}

.input-container-activity.medium {
  width: 46px;
  background-color: #565656;
  border: 0;
  color: white;
  text-align: center;
  font-size: 0.875rem; /* Equivalent to text-sm */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  padding: 0.375rem; /* Equivalent to p-1.5 */
  font-size: 18px;
  box-shadow: none; /* Equivalent to focus:ring-0 */
  placeholder-color: white;
}

.input-container-activity.large {
  width: 61px;
  background-color: #565656;
  border: 0;
  color: white;
  text-align: center;
  font-size: 18px; /* Equivalent to text-sm */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  padding: 0.375rem; /* Equivalent to p-1.5 */
  font-size: 18px;
  box-shadow: none; /* Equivalent to focus:ring-0 */
  placeholder-color: white;
  font-family: var(--paragraph-font);
}

.checkbox-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 18px;
}

/* Responsive */
.food-plan-conatiner {
  background: var(--main-background-gradient);
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #565656;
}
.food-plan-conatiner p {
  margin: 0;
}
.food-plan-conatiner-flex {
  /* background-color: green; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.food-plan-flex {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.food-title {
  font-family: var(--title-font);
  font-size: 29px;
}

.food-plan-check-box {
  display: none;
}

.food-plan-check-box-label {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  text-align: center;
  line-height: 24px; /* Aligns the checkmark */
  font-size: 16px; /* Adjust the size of the checkmark */
  color: transparent; /* Initially hidden */
}

.food-plan-check-box:checked + .food-plan-check-box-label::after {
  content: "✔"; /* Adds the tick */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #a0fc6e; /* Tick color */
  font-size: 14px; /* Adjust size of the tick */
  font-weight: bold;
}

.food-para {
  font-family: var(--paragraph-font);
  font-size: 18px;
}
.food-para-setimane {
  font-family: var(--title-font);
  font-size: 16px;
}

.food-para.margin {
  margin-bottom: 10px;
}
.select-flex {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-bottom: 20px;
}

.nutrition-table {
  width: 100%;
  color: #fff;
  font-family: Arial, sans-serif;
  margin: 0px;
  border-spacing: 10px;
  border-collapse: separate;
  background-color: transparent;
  font-family: var(--paragraph-font);
}
.nutrition-table tbody {
}

.nutrition-table-head {
  background-color: #111;
  font-size: 18px;
  font-weight: 100;
}
.nutrition-table th,
.nutrition-table td {
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.nutrition-table th {
  font-size: 18px;
}

.nutrition-table td {
  background-color: #3a3a3a;
  border-radius: 8px;
  font-size: 18px;
}
.box-space {
  background-color: #41b104;
  padding: 0px 0px;
}
.nutrition-table td:nth-child(1) {
  background-color: transparent;
  text-align: left;
}

.measurement-table {
  width: 100%;
  border-collapse: collapse;
  color: #fff;
  font-family: Arial, sans-serif;
  margin: 0px;
  border-collapse: separate;
  border-spacing: 12px;
  background-color: transparent;
  font-family: var(--paragraph-font);
}

.measurement-table-head {
  background-color: #111;
}
.measurement-table th,
.measurement-table td {
  /* padding: 5px 20px; */
}

.measurement-table th {
  font-weight: bold;
  font-size: 14px;
  background-color: #000000;
  padding: 10px 20px;
}

.measurement-table td {
  /* background-color: #3a3a3a; */
  border-radius: 8px;
}
.measurement-table td:nth-child(1) {
  background-color: transparent;
  width: 100px;
}

.giorno-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.giorno-flex-title {
  font-size: 41px;
  font-family: var(--title-font);
}
.NutritionTable {
  border-top: 1px solid gray;
}

.dashboard-container {
  display: flex;
  justify-content: space-around;
  background: var(--main-background-gradient);
  border-radius: 4px;
  color: white;
  padding: 16px;
}

.dashboard-card {
  text-align: left;
  padding: 16px;
  border-left: 1px solid gray;
  width: 100%;
}
.ratio-val {
  display: flex;
  align-items: center;
  gap: 40px;
}
.ratio {
  color: #41b104;
  font-family: var(--paragraph-font);
  font-size: 18px;
}

.dashboard-card:first-child {
  border-left: none;
}

.value {
  font-size: 51px;
  /* font-size: 3rem; */
  font-family: var(--title-font);
}

.label {
  font-family: var(--title-font);
  font-size: 24px;
}

.measurment-flex-two-img {
  border-radius: 8px;
  /* padding: 0px 47px; */
}
.measurment-container {
  margin-top: 50px;
}
.measurmentss-card {
  display: flex;
  gap: 10px;
  font-family: var(--paragraph-font);
  font-size: 18px;
  align-items: center;
  margin-bottom: 10px;
}
.light-blue-clr {
  width: 18px;
  height: 18px;
  background-color: #92b8fd;
}
.green-clr {
  background-color: #b8fd92;
  width: 18px;
  height: 18px;
}
.dark-blue-clr {
  background-color: #022059;
  width: 18px;
  height: 18px;
}

.measurment-flex-card {
  background: var(--main-background-gradient);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.measurment-card-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.measurment-card-title {
  font-size: 16px;
  font-family: var(--title-font);
}
.rating-num {
  margin-right: 40px;
  font-size: 33px;
  color: var(--main-green-color);
}

.rating-num-two {
  margin-right: 62px;
  font-size: 33px;
  color: #da1e28;
}

.percent-one {
  color: var(--main-green-color);
}

.percent-two {
  color: #da1e28;
}
.Progressi-title {
  font-family: var(--title-font);
  font-size: 41px;
}
th {
  font-weight: 100;
}
.measurement-tables {
  width: 93%;
  background: transparent;
  /* margin-left: 30px; */
  margin: 0px;
  border-spacing: 0;
  font-family: var(--paragraph-font);
  font-size: 18px;
}

.measurement-table-heading {
  font-family: var(--title-font);
  font-size: 41px;
}
.foto-flex {
  display: flex;
  gap: 12px;
  margin-top: 12px;
}
.foto-card {
  width: 127px;
  height: 215px;
  background: var(--main-background-gradient);
  border-radius: 8px;
}

.measurement-tables th {
  padding: 10px;
  text-align: left;
}

.measurement-tables thead {
  background-color: #000;
}

.measurement-tables td label {
  background-color: var(--blue-secondary-700);
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
}

.measurement-tables td {
  font-family: var(--paragraph-font);
  font-size: 15px;
  color: var(--main-text-color);
  padding: 10px;
}
.table-flex {
  display: flex;
  gap: 20px;
}

.table-flex table {
  width: 93%;
  background: transparent;
  border-spacing: 0 10px; /* Adds vertical spacing between rows */
  border-collapse: separate;
  border-spacing: 10px;
  font-family: var(--paragraph-font);
  font-family: var(--paragraph-font);
}
.last-table {
  width: 93%;
  background: transparent;
  border-spacing: 0 10px; /* Adds vertical spacing between rows */
  border-collapse: separate;
  border-spacing: 10px;
  font-family: var(--paragraph-font);
  font-family: var(--paragraph-font);
}

.last-table th {
  font-weight: 100;
}
.table-flex th {
  font-weight: 100;
}

.table-flex thead {
  background-color: #000;
}
.table-one {
  width: 100%;
}
.table-two {
  width: 100%;
}

.workout-container {
  padding: 20px;
  color: #fff;
  font-family: Arial, sans-serif;
}

.activity {
  margin-bottom: 20px;
}

.activity-header,
.exercise-header {
  display: flex;
  /* justify-content: space-between; */
  gap: 24px;
  margin-bottom: 10px;
  font-size: 24px;
  font-family: var(--title-font);
}
.activity-details {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  margin-left: 41px;
}

.exercise-details {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
/* Add horizontal scroll for exercises */

.activity-select,
.exercise-select,
.activity-input,
.exercise-input {
  background-color: #555;
  border: none;
  padding: 5px;
  color: #fff;
  margin-right: 5px;
  width: 100px;
  text-align: center;
  border-radius: 8px;
  height: 32px;
}
.activity-input.small {
  width: 32px;
  text-align: center;
}
.progression {
  display: flex;
  align-items: center;
}

.progression span {
  margin-right: 5px;
  font-family: var(--title-font);
  font-size: 24px;
}

.progression {
  display: flex;
  align-items: center;
}

.progression-group {
  display: flex;
  align-items: center;
}

.separator {
  color: #aaa;
}
.progression-input {
  width: 50px;
  background-color: #555;
  border: none;
  padding: 5px;
  color: #fff;
  margin-right: 5px;
  text-align: center;
  border-radius: 8px;
}
.activity-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
}
.progression-inputss-grouping {
  display: flex;
}
.training-right-content {
  display: flex;
  align-items: end;
  justify-content: end;
  flex-direction: column;

  font-family: var(--title-font);
}
.training-right-content-p1 {
  color: #da1e28;
  font-size: 16px;
}
.training-right-content-p2 {
  color: #f6f6f6;
  margin-left: 30px;
  font-size: 16px;
}
.training-right-content-flex {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .exercise {
    /* background-color: green; */
    /* overflow-x: auto; */
    margin-left: 37px;
  }
  .ResponsiveHeader {
    display: block;
  }

  .ResponsiveHeader-flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .admin-coach-step-header-container {
    display: none;
  }

  .content-columns {
    /* margin-top: 30px;
    margin-right: 30px; */
    display: flex;
    /* flex-direction: row; */
    gap: 1em;

    flex-direction: column;
  }

  .content-right-sidebar-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    background-color: green;
    padding: 20px;
  }

  .right-sidebar {
    background: var(--main-background-gradient);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .content-columns .content-center {
    width: 100%;
  }

  .content-columns .content-right-sidebar {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.5em;
  }

  .content-columns .content-right-sidebar .button-default-no-icon {
    width: 90%;
  }

  .content-right-sidebar .button-default-no-icon {
    width: 30%;
    margin-top: 30px;
  }

  .content-columns .content-right-sidebar .task-body {
    display: flex;
    gap: 1em;
  }

  .task-editor .title {
    margin-bottom: 10px;
  }

  .task-editor .task-body {
    margin-bottom: 10px;
  }

  .content-columns .content-right-sidebar .card .title > img {
    margin-right: 0.3em;
  }

  .content-columns .content-right-sidebar .card .column {
    flex-direction: column;
  }

  .content-columns .content-right-sidebar .card .tags.column {
    align-items: center;
  }

  .content-columns .content-right-sidebar .card .tags.column .tag {
    width: 90%;
  }
  .content-right-sidebar .card {
    width: 100%;
  }

  .exercise-details {
    display: inline-flex; /* Keeps child elements in a horizontal line */
    align-items: center;
  }
  .admin-coach-step-two-container-flex {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .step-2 {
    width: 100%;
  }

  .step-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .admin-atletet-step-five-container {
    display: flex;
    gap: 20px;
    margin-top: 30px;
    width: 100%;
    flex-direction: column;
  }
  /* Container and child elements */
  .Training-container-cards-flex {
    display: flex;
    margin-top: 0.75rem;
    gap: 20px;
    flex-direction: column;
  }

  .admin-atletet-step-five-card-one {
    background: var(--main-background-gradient);
    width: 100%;
    border-radius: 8px;
  }

  .admin-atletet-step-five-card-two {
    width: 100%;
  }

  .Training-container-cards {
    /* background-color: yellow; */
  }

  .measurment-flex {
    display: flex;
    gap: 24px;
    margin-top: 30px;
    width: 90%;
  }
  .measurment-flex-one {
    width: 100%;
  }
  .measurment-flex-two {
    width: 50%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--main-background-gradient);
  }

  .measurment-flex {
    display: flex;
    gap: 24px;
    margin-top: 30px;
    width: 100%;

    flex-direction: column;
  }
  .measurment-flex-one {
    width: 100%;
  }
  .measurment-flex-two {
    width: 100%;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--main-background-gradient);
  }

  .measurment-card {
    margin: 25px 0px 40px 0px;
    background: var(--main-background-gradient);
    padding: 24px 30px;
    border-radius: 10px;
    display: block;
    width: 100%;
  }
  .measurment-flex-cards {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: column;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 20px;
  }
}

/* Tablets/Laptops (601px to 1024px) */
@media (min-width: 601px) and (max-width: 1024px) {
  .exercise {
    /* background-color: yellow; */
    /* overflow-x: auto; */
    margin-left: 37px;
  }
  .ResponsiveHeader {
    display: block;
  }
  .ResponsiveHeader-flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .admin-coach-step-header-container {
    display: none;
  }

  .content-columns {
    /* margin-top: 30px;
    margin-right: 30px; */
    display: flex;
    /* flex-direction: row; */
    gap: 1em;

    flex-direction: column;
  }

  .content-right-sidebar-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .right-sidebar {
    /* background: var(--main-background-gradient); */
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .content-right-sidebar .card {
    width: 100%;
  }
  .content-columns .content-center {
    width: 100%;
  }

  .content-columns .content-right-sidebar {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.5em;
  }

  .content-columns .content-right-sidebar .button-default-no-icon {
    width: 90%;
  }

  .content-right-sidebar .button-default-no-icon {
    width: 30%;
    margin-top: 30px;
  }

  .content-columns .content-right-sidebar .task-body {
    display: flex;
    gap: 1em;
  }

  .task-editor .title {
    margin-bottom: 10px;
  }

  .task-editor .task-body {
    margin-bottom: 10px;
  }

  .content-columns .content-right-sidebar .card .title > img {
    margin-right: 0.3em;
  }

  .content-columns .content-right-sidebar .card .column {
    flex-direction: column;
  }

  .content-columns .content-right-sidebar .card .tags.column {
    align-items: center;
  }

  .content-columns .content-right-sidebar .card .tags.column .tag {
    width: 90%;
  }
  .admin-coach-step-two-container-flex {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  .step-2 {
    width: 100%;
  }

  .step-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  /* Container and child elements */
  .Training-container-cards-flex {
    display: flex;
    margin-top: 0.75rem;
    gap: 20px;
    flex-direction: column;
  }

  .admin-atletet-step-five-card-one {
    background: var(--main-background-gradient);
    width: 100%;
    border-radius: 8px;
  }

  .admin-atletet-step-five-card-two {
    width: 100%;
  }

  .Training-container-cards {
    /* background-color: yellow; */
  }

  .measurment-flex {
    display: flex;
    gap: 24px;
    margin-top: 30px;
    width: 100%;

    flex-direction: column;
  }
  .measurment-flex-one {
    width: 100%;
  }
  .measurment-flex-two {
    width: 100%;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--main-background-gradient);
  }

  .measurment-card {
    margin: 25px 0px 40px 0px;
    background: var(--main-background-gradient);
    padding: 24px 30px;
    border-radius: 10px;
    width: 100%;
  }

  .measurment-flex-cards {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: column;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 20px;
  }
}

/* Desktops (1025px and above) */
@media (min-width: 1025px) {
  .exercise {
    /* background-color: purple; */
    /* overflow-x: auto; */
    margin-left: 37px;
  }
  .form-container {
    display: flex;
    flex-direction: column;
  }
  .admin-coach-step-header-container {
    display: block;
  }
  .ResponsiveHeader {
    display: none;
  }

  .content-columns {
    /* margin-top: 30px;
    margin-right: 30px; */
    display: flex;
    flex-direction: row;
    gap: 1em;
  }

  .content-right-sidebar-container {
    display: flex;
    width: 25%;
    flex-direction: column;
    gap: 20px;
  }

  .right-sidebar {
    /* background: var(--main-background-gradient); */
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .content-columns .content-center {
    width: 70%;
  }

  .content-columns .content-right-sidebar {
    display: flex;
    width: 30%;
    flex-direction: column;
    gap: 0.5em;
  }

  .content-columns .content-right-sidebar .button-default-no-icon {
    width: 90%;
  }

  .content-right-sidebar .button-default-no-icon {
    width: 30%;
    margin-top: 30px;
  }

  .content-columns .content-right-sidebar .task-body {
    display: flex;
    gap: 1em;
  }

  .task-editor .title {
    margin-bottom: 10px;
  }

  .task-editor .task-body {
    margin-bottom: 10px;
  }

  .content-columns .content-right-sidebar .card .title > img {
    margin-right: 0.3em;
  }

  .content-columns .content-right-sidebar .card .column {
    flex-direction: column;
  }

  .content-columns .content-right-sidebar .card .tags.column {
    align-items: center;
  }

  .content-columns .content-right-sidebar .card .tags.column .tag {
    width: 90%;
  }

  /* .exercise {
    background-color: blue;
    overflow-x: hidden;
  } */
  .admin-coach-step-two-container-flex {
    display: flex;
    gap: 20px;
    flex-direction: row;
    margin-top: 30px;
  }
  .step-2 {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .step-1 {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .admin-atletet-step-five-container {
    display: flex;
    gap: 20px;
    margin-top: 30px;
    width: 100%;
  }
  /* Container and child elements */
  .Training-container-cards-flex {
    display: flex;
    margin-top: 0.75rem;
    gap: 20px;
    margin-top: 10px;
  }

  .admin-atletet-step-five-card-one {
    background: var(--main-background-gradient);
    width: 70%;
    border-radius: 8px;
  }

  .admin-atletet-step-five-card-two {
    width: 30%;
  }

  .Training-container-cards {
    /* background-color: yellow; */
  }

  .measurment-flex {
    display: flex;
    gap: 24px;
    margin-top: 30px;
    width: 90%;
  }
  .measurment-flex-one {
    width: 100%;
  }
  .measurment-flex-two {
    width: 50%;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--main-background-gradient);
  }
  .measurment-card {
    margin: 25px 0px 40px 0px;
    background: var(--main-background-gradient);
    padding: 24px 30px;
    border-radius: 10px;
    width: 220px;
  }
  .measurment-flex-cards {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: row;
  }
}
