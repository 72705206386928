.login-content {
  margin: 0 auto;
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1rem;
}

.content > div {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

footer {
  display: none;
}

form {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.oppure {
  align-self: center;
  font-family: var(--paragraph-font);
}
.show-password {
  font-family: var(--paragraph-font);
}

.margin-top {
  margin: 5px 0px;
}

.headline {
  color: var(--neutral-white-200);
  text-align: center;
  font-family: var(--title-font);
  font-size: 51px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.text-input .title,
.button-default-no-icon,
.button-default-no-icon.outline {
  text-transform: uppercase;
}

.social-login-buttons {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 1em 0;
}
