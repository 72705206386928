* {
  /* border: 1px solid red; */
}

:root {
  /* Main mobile variables */
  --mobile-header-height: 35px;
  --mobile-header-padding: 10px 24px;
  --mobile-body-container-padding: 30px 16px;
  --mobile-footer-padding: 16px 0;
  --mobile-footer-height: 90px;

  /* Desktop variables */
  --desktop-siderbar-min-width: calc(var(--max-site-width) * 0.1);
  --content-left-margin-to-avoid-sidebar: calc(
    var(--desktop-siderbar-min-width) + 5em
  ); /* Check Media queries, transforms to 0 on to mobile transition */

  /* Main variables */
  --max-site-width: 1392px;
  --slim-desktop-width: 500px;
  --margin-to-avoid-header-overlap: calc(var(--mobile-header-height) + 20px);
}

body {
  background: fixed var(--main-background-gradient);
  color: var(--main-text-color);
  margin: 0;
  max-width: 100vw;
}

header {
  /* height: var(--mobile-header-height); */
  height: 50px;
  background-image: var(--main-background-gradient);
  display: flex;
  flex-direction: row;
  padding: var(--mobile-header-padding);
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

header .header-container {
  width: 100%;
  max-width: var(--max-site-width);
  margin-left: auto;
  margin-right: auto;
}

header .logo-icon {
  position: relative;
  width: 19px;
  height: 35px;
}

/* main-container includes both content and side navigation bar */
.main-container {
  margin-top: var(--margin-to-avoid-header-overlap);
  margin-bottom: calc(var(--mobile-footer-height) + 1em);
  max-width: var(--max-site-width);
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

content {
  margin-left: 0; /* Check Media queries, transforms to 0 on to mobile transition */
  font-family: var(--paragraph-font);
  padding: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
}

section {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

section > .title {
  color: var(--neutral-white-200, #f6f6f6);
  font-family: var(--title-font);
  font-size: 41px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.title-font {
  font-family: var(--title-font);
}

/* Sidebar menu styling 

inherited from components.css

*/

.navigation-bar.desktop {
  position: fixed;
  top: calc(var(--mobile-header-height));
  min-width: var(--desktop-siderbar-min-width);
  padding: 1em;
  padding-top: 3em;
  display: none; /* See media queries */
  background: var(--neutral-black-700, #0f0f0f);
  height: 100%;
}

/* Footer menu styling */
footer {
  padding: var(--mobile-footer-padding);
  background-image: var(--main-background-gradient);
  font-family: var(--title-font);
  position: fixed;
  bottom: 0;
  width: 100%;
}

footer .navigation-bar {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

footer img {
  width: 24px;
  height: 24px;
  padding-bottom: 4px;
}

footer img:hover,
footer .active {
  color: var(--main-green-color);
}

footer .nav-icon {
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 768px) {
  footer {
    display: none;
  }

  content {
    margin-left: var(--content-left-margin-to-avoid-sidebar);
    border-radius: 8px;
  }

  .main-container {
    padding-top: 2em;
  }

  .navigation-bar.desktop {
    display: initial;
  }
}

/* Component css  */

/*
	COMPONENTS & BEHAVIORS
*/
@font-face {
  font-family: "Familjen Grotesk Regular";
  src: url("./assets/fonts/FamiljenGrotesk-Variable.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bebas Neue Regular";
  src: url("./assets/fonts/BebasNeue-Regular.ttf") format("truetype"),
    url("./assets/fonts/BebasNeue-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

:root {
  /* Main colors */
  --main-background-gradient: linear-gradient(to bottom, #1e1e1e, #0c0c0c);

  --main-text-color: #f6f6f6;
  --main-green-color: #a0fc6e;
  --main-blue-color: #0441b1;
  --yellow-warning-color: #dabc1e;
  --red-error-color: #da1e28;

  /* Colors from Figma */
  --green-primary-200: #e7fedb;
  --green-primary-300: #d0feb7;
  --green-primary-400: #b8fd92;
  --green-primary-500: #a0fc6e;
  --green-primary-600: #66fa15;
  --green-primary-700: #41b104;
  --green-primary-800: #205902;

  --blue-secondary-200: #dbe7fe;
  --blue-secondary-300: #b7d0fe;
  --blue-secondary-400: #92b8fd;
  --blue-secondary-500: #78a7fc;
  --blue-secondary-600: #1566fa;
  --blue-secondary-700: #0441b1;
  --blue-secondary-800: #022059;

  --neutral-black-300: #8f8f8f;
  --neutral-black-400: #565656;
  --neutral-black-500: #1e1e1e;
  --neutral-black-600: #161616;
  --neutral-black-700: #0f0f0f;
  --neutral-black-800: #080808;

  --neutral-white-200: #f6f6f6;
  --neutral-white-300: #ececec;
  --neutral-white-400: #e3e3e3;
  --neutral-white-500: #d9d9d9;

  --yellow-warning: #dabc1e;
  --red-error: #da1e28;

  /* Main font variables */

  --title-font: "Bebas Neue Regular", sans-serif;
  --button-font: "Bebas Neue Regular", sans-serif;
  --paragraph-font: "Familjen Grotesk Regular", sans-serif;
}
body {
  font-family: var(--title-font);
}

.components-container {
  max-width: 768px;
  margin: auto;
  border: 1px dotted greenyellow;
  padding: 1em;
  background: var(--neutral-black-500);
}

.components-container p > a,
.components-container p > a:visited {
  color: lightblue;
}

.components-container h1 {
  border-bottom: 2px dashed greenyellow;
  margin: 2em 0;
}

.components-container h2 {
  border-bottom: 1px dashed greenyellow;
  margin: 2em 0;
}

.components-container textarea {
  width: 100%;
  height: 25vh;
  background-color: black;
  color: greenyellow;
  margin: 1em 0;
}

.components-container li {
  margin-bottom: 0.5em;
}

/* Some general appicable styling */

.title {
  font-family: var(--title-font);
}

.description {
  font-family: var(--paragraph-font);
}
.desc-font {
  font-size: 18px;
}

.active {
  color: var(--green-primary-500);
}

/* Buttons */
/* Remember that we have a.button and button.button, so in case
of modifying styles you have to do it on both */

/* button.button */

button.button-default-no-icon {
  height: 40px;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral-black-500);
  background-color: var(--main-green-color);
  font-size: 16px;
  font-family: var(--button-font);
  text-decoration: none;
  clip-path: polygon(1em 0%, 100% 0%, calc(100% - 1em) 100%, 0% 100%);
  border: none;
  cursor: pointer;
}
a {
  text-decoration: none;
}

button.button-default-no-icon:active {
  background: var(--green-primary-700);
}

button.button-default-no-icon.outline {
  color: var(--main-green-color);
  background-color: inherit;
}

button.button-default-no-icon.outline:active {
  color: var(--green-primary-700);
  background-color: inherit;
}

button.button-default-no-icon.deactive {
  color: var(--neutral-black-400);
  background-color: var(--neutral-white-500);
}

/* Square Buttons */

button.button-square {
  height: 40px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral-black-500);
  background-color: var(--main-green-color);
  font-size: 16px;
  font-family: var(--button-font);
  text-decoration: none;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.btn-mar-bottom {
  margin-bottom: 10px;
}

button.button-square:active {
  background: var(--green-primary-700);
}

button.button-square.softblue {
  background-color: var(--blue-secondary-500);
  color: var(--neutral-white-200);
}

button.button-square.softblue:active {
  background-color: var(--blue-secondary-700);
  color: var(--neutral-white-200);
}

button.button-square.strongblue {
  background-color: var(--blue-secondary-700);
  color: var(--neutral-white-200);
}

button.button-square.strongblue:active {
  background-color: var(--blue-secondary-800);
  color: var(--neutral-white-200);
}

/* a.button */

a.button-default-no-icon {
  height: 40px;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral-black-500);
  background-color: var(--main-green-color);
  font-size: 16px;
  font-family: var(--button-font);
  text-decoration: none;
  clip-path: polygon(1em 0%, 100% 0%, calc(100% - 1em) 100%, 0% 100%);
  width: 100%;
}

button.button-default-no-icon:hover {
  background: var(--green-primary-700);
}

a.button-default-no-icon.outline {
  color: var(--main-green-color);
  background-color: inherit;
}

a.button-default-no-icon.outline:hover {
  color: var(--green-primary-700);
  background-color: inherit;
}

a.button-default-no-icon.deactive {
  color: var(--neutral-black-400);
  background-color: var(--neutral-white-500);
}

a.button-default-no-icon.deactive:hover {
  color: var(--neutral-black-400);
  background-color: var(--neutral-white-500);
}

/* a Square Buttons */

a.button-square {
  height: 40px;
  padding: 0px 16px;
  display: flex;

  justify-content: center;
  align-items: center;
  color: var(--neutral-black-500);
  background-color: var(--main-green-color);
  font-size: 16px;
  font-family: var(--button-font);
  text-decoration: none;
  border-radius: 4px;
}

a.button-square.softblue {
  background-color: var(--blue-secondary-500);
  color: var(--neutral-white-200);
}

a.button-square.strongblue {
  background-color: var(--blue-secondary-700);
  color: var(--neutral-white-200);
}

/*  Top Bar */

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  height: 3em;
  padding-top: 65px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--green-primary-500, #a0fc6e);
  /* margin-bottom: 2em; */
}

.top-bar .title {
  color: var(--neutral-white-200);
  font-family: var(--title-font);
  color: var(--neutral-white-200, #f6f6f6);
  font-size: 33px;
  font-style: normal;

  font-weight: 400;
  line-height: normal;
}

.top-bar > div {
  display: flex;
}

/* Text input */
.text-input {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.text-input .title {
  color: var(--neutral-white-200);
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-input .input {
  padding: 7px 16px;
  border-bottom: 1px solid var(--neutral-white-200);
  width: fill-available;
  width: -moz-available;
  width: -webkit-fill-available;
}

.text-input input,
.text-input input:focus-visible {
  background: transparent;
  color: var(--main-text-color);
  font-family: var(--paragraph-font);
  width: 100%;
  border: none;
  outline: none;
}
.text-input .helper-text {
  color: var(--neutral-black-300);
  font-family: var(--paragraph-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Date components */
.timer-controls {
  display: flex;
  gap: 10px;
}

/* Calendar */

.week-calendar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  font-family: var(--paragraph-font);
}

.week-calendar .week-chooser {
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  font-family: var(--title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-content: space-between;
}

.week-calendar .week-chooser .arrow {
  /* width: 48px; */
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.week-calendar .week-days {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  /* background-color: #205902; */
}

.week-calendar .week-day {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1%;
  gap: 4px;
  border-radius: 8px;
  color: var(--neutral-black-300);
  cursor: pointer;
}

.week-calendar .week-day .day-month,
.week-calendar .week-day .day-year {
  display: none;
}

.week-calendar .active {
  background: var(--green-primary-500);
  color: var(--neutral-black-500);
}

/* Date picker */

.date-picker {
  width: auto;
}

.date-picker .date-input {
  display: flex;
  flex-direction: row;
  background: var(--neutral-black-400);
  height: 48px;
  align-content: center;
  align-items: center;
  gap: 1em;
  padding: 0 1em;
}

.date-picker input,
.date-picker input:focus-visible {
  background: transparent;
  font-family: var(--paragraph-font);
  color: var(--neutral-white-200);
  width: 90%;
  border: none;
  outline: none;
}

.date-picker .month-calendar {
  background: var(--main-background-gradient);
  padding: 12px 8px;
}

.date-picker .month-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1em 0;
  cursor: pointer;
}

.date-picker ul.week-day-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 24px;
  margin-bottom: 23px;
}

.date-picker ul.week-day-bar li {
  width: 14%;
  display: flex;
  justify-content: center;
  color: var(--green-primary-500);
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.date-picker ul.day-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

.date-picker li.month-day {
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  border: 1px solid transparent;
  cursor: pointer;
}

.date-picker li.month-day:hover,
.date-picker li.month-day:active {
  border: 1px dotted var(--green-primary-400);
  border-radius: 18px;
}

.date-picker .month-number,
.date-picker .month-year {
  display: none;
}
.curr-month {
  text-align: center;
  padding: 10px 0px;
  margin-top: 20px;
  font-family: var(--title-font);
}

/* Time picker */

.time-picker {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.time-picker .title {
  color: var(--neutral-white-200);
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.time-picker div {
  display: flex;
  align-items: center;
}

.time-picker .clock {
  display: flex;
  background: var(--neutral-black-400);
  color: white;
  border-radius: 4px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--title-font);
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 1em;
  border: none;
  outline: none;
  -moz-appearance: textfield;
  text-align: center;
}

.time-picker .clock::-webkit-inner-spin-button,
.time-picker .clock::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.time-picker .separator {
  color: white;
  font-family: var(--title-font);
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 4px;
}

/* Navigation */

.navigation-bar {
  display: flex;
  font-family: var(--title-font);
  padding-top: 30px;
}

.navigation-bar a,
.navigation-bar a:visited {
  text-decoration: none;
  color: var(--main-text-color);
}

.nav-icon {
  min-width: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
}

.nav-icon.active,
.nav-icon.active a {
  color: var(--main-green-color);
}

/* Navigation - top */

.navigation-bar.top {
  flex-direction: row;
  color: var(--neutral-black-300);
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.navigation-bar.top .nav-icon {
  display: flex;
  min-width: 24px;
  min-height: 24px;
  padding: 8px 12px;
  width: 100%;
  justify-content: center;
  cursor: pointer;
}

.navigation-bar.top .active {
  color: var(--neutral-white-200);
  border-bottom: 1px solid var(--green-primary-500);
}

/* Navigation - side (desktop) */

.navigation-bar.desktop {
  flex-direction: column;
  font-size: 29px;
}

.navigation-bar.desktop .nav-icon {
  gap: 8px;
}

/* Navigation - bottom (mobile) */

.navigation-bar.mobile {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
}

.navigation-bar.mobile .nav-icon {
  justify-content: center;
}

.navigation-bar.mobile .nav-icon a {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

/* Personalized scrollbar */

/* styling example container, do not copy */
.scroll-content {
  display: block;
  width: 90%;
  height: 25vh;
  padding: 20px;
  overflow: scroll;
  white-space: pre;
}

/* Styles that apply to any container with overflow */
.scroll-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scroll-content::-webkit-scrollbar-track {
  background-color: var(--neutral-black-400);
  border-radius: 4px;
}

.scroll-content::-webkit-scrollbar-thumb {
  background-color: var(--neutral-black-600);
  border-radius: 4px;
  border: 2px solid var(--neutral-black-400);
}

.scroll-content::-webkit-scrollbar-corner {
  background-color: var(--neutral-black-400);
}

.scroll-content::-webkit-scrollbar-thumb:hover {
  background-color: var(--neutral-black-600);
}

/* Form control */

/* inputs */

label.input-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.2em;
  margin-bottom: 14px;
  font-size: 18px;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 0;
}

input[type="checkbox"]:checked {
  background-position: center;
}

input[type="checkbox"].round-checkbox {
  background: url(./assets/img/icons/circle.svg);
}

input[type="checkbox"]:checked.round-checkbox {
  background: url(./assets/img/icons/check-circle-active.svg);
}

input[type="checkbox"].square-checkbox {
  background: url(./assets/img/icons/square.svg);
}

input[type="checkbox"]:checked.square-checkbox {
  background: url(./assets/img/icons/square-fill-active.svg);
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 0;
  background: url(./assets/img/icons/circle.svg);
}

input[type="radio"]:checked {
  background: url(./assets/img/icons/circle-radio-active.svg);
  background-position: center;
}

/* tags */

.tags {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 4px 0;
  color: var(--main-text-color);
}

.tags .tag {
  font-family: var(--paragraph-font);
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--blue-secondary-700);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.tags .tag.outline {
  background: transparent;
  color: var(--main-text-color);
  border: 1px solid var(--blue-secondary-700);
}

.tags .tag sup {
  position: relative;
  top: -0.5em;
  margin-right: 1px;
}

/* Tooltip */

/*
	* Tooltip.js styles
	*/

.tooltip-js {
  position: absolute;
  background: var(--neutral-black-600);
  border: 1px dotted var(--main-green-color);
  color: #ffffff;
  padding: 8px;
  z-index: 999;
}

.tooltip-js.alt-tooltip {
  background: #490b22;
}

/* form-indicator */

.form-indicator {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.form-indicator .title {
  color: var(--neutral-white-200);
  font-family: var(--title-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

.form-indicator .controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--neutral-white-200);
  font-family: var(--paragraph-font);
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-indicator .controls .plus,
.form-indicator .controls .minus {
  cursor: pointer;
}

/* Form input button */

.form-input-button {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.form-input-button .title {
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-input-button .options {
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
  justify-content: space-between;
  gap: 4px;
}

.form-input-button .options div {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
  gap: 4px;
  padding: 7px;
  border: 1px solid var(--neutral-white-200, #f6f6f6);
  cursor: pointer;
}

.form-input-button .options div:hover {
  background-color: var(--main-green-color);
  border: 1px solid var(--main-green-color);
  color: var(--neutral-black-700);
}

.form-input-button .description {
  color: var(--neutral-black-300);
  font-family: var(--paragraph-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-input-button .active {
  background-color: var(--green-primary-500);
  color: var(--neutral-black-500);
}

/* form toggle checkbox */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 32px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--neutral-black-400);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: var(--neutral-black-500);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: var(--green-primary-500);
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px var(--green-primary-500);
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 16px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

/* Upload form */

.upload-form {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-transform: uppercase;
}

.upload-form input {
  display: none;
}

.upload-form .title {
  color: var(--neutral-white-200);
  font-family: var(--title-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upload-form .upload-area {
  display: flex;
  flex-direction: row;
  color: var(--neutral-white-200);
  border: 1px solid var(--neutral-white-200);
  padding: 8px 30px 8px 24px;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
}
.upload-form .success {
  display: flex;
  flex-direction: row;
  padding: 8px 30px 8px 24px;
  align-items: flex-start;
  gap: 10px;
  color: var(--neutral-black-500);
  background: var(--green-primary-500);
  cursor: pointer;
}

/* Progress bar */

.progress-bar {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.progress-bar .title {
  color: var(--main-text-color);
  font-family: var(--title-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.progress-bar .description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.progress-bar progress {
  border: none;
  width: 100%;
  height: 48px;
  cursor: pointer;
}

/* FF and chrome backgrounds respectively (yeah, confusing) */
.progress-bar progress[value] {
  background: url(./assets/img/components/background-progress-bar.svg);
}
.progress-bar progress::-webkit-progress-bar {
  background: url(./assets/img/components/background-progress-bar.svg);
}

/* FF and chrome values respectively */
.progress-bar progress::-moz-progress-bar {
  background-color: var(--green-primary-500);
}
.progress-bar progress::-webkit-progress-value {
  background-color: var(--green-primary-500);
}

/* step indicator */

.step-indicator {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--neutral-white-200);
  text-align: center;
  font-family: var(--title-font);
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step-indicator .description {
  color: var(--neutral-black-300);
  font-family: var(--button-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step-indicator .description,
.step-indicator .title {
  text-align: left;
}

.step-indicator .step-area {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.step-indicator .step {
  height: 6px;
  width: 100%;
  background-color: white;
  clip-path: polygon(0.2em 0%, 100% 0%, calc(100% - 0.2em) 100%, 0% 100%);
}

.step-indicator .step.active {
  height: 6px;
  width: 100%;
  background-color: var(--green-primary-500);
  clip-path: polygon(0.2em 0%, 100% 0%, calc(100% - 0.2em) 100%, 0% 100%);
}

/* Combo box */

.weeksData {
  display: flex !important;
  /* flex-direction: row; */
  flex-wrap: wrap;
  padding: 10px;
  gap: 10px;
}
.trainingBox {
  width: 100%;
  margin-top: 10px;
}

.excerciseCardParent {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #a0fc6e #131313;
}

.excerciseCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: linear-gradient(180deg, #1e1e1e 0%, #0c0c0c 100%);
  cursor: pointer;
}

/* Drowpdown menu

	We have to remove default browser styling first
	*/

details > summary {
  list-style: none;
}
summary::-webkit-details-marker {
  display: none;
}

.dropdown-menu {
  border: none;
  font-family: var(--button-font);
  cursor: pointer;
}

.dropdown-menu a,
.dropdown-menu a:visited {
  color: var(--main-text-color);
  text-decoration: none;
}

/* .dropdown-menu summary:after {
  content: url(./assets/img/icons/chevron-down.svg);
} */
.dropdown-menu summary::after {
  content: url(./assets/img/icons/chevron-down.svg);
  margin-left: 5px;
}

.dropdown-menu[open] summary::after {
  content: url(./assets/img/icons/chevron-up.svg);
}

.dropdown-menu summary {
  border: 1px solid var(--main-green-color);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.dropdown-menu ul {
  margin: 0;
  padding: 0;
  position: absolute;
  background: var(--neutral-black-700);
  z-index: 999;
}

.dropdown-menu li {
  border: 1px solid var(--main-green-color);
  list-style: none;
  margin: 0;
  padding: 8px 16px;
}

/* Drowpdown menu with input select */

select.dropdown-menu {
  border: 1px solid var(--main-green-color);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  color: white;
  width: 100%;
}

select.dropdown-menu option {
  border: 1px solid var(--main-green-color);
  background: var(--neutral-black-700);
  margin: 0;
  padding: 8px 16px;
}

/* Total box */

.total-box {
  /* height: 89px;
  width: 165px; */
  border-radius: 8px;
  padding: 12px 8px;
  background: var(--main-background-gradient);
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: var(--title-font);
  font-size: 16px;
  width: 100%;
}

.coach-total-box {
  border-radius: 8px;
  padding: 12px 8px;
  background: var(--main-background-gradient);
  background-position: center;
  background-repeat: no-repeat;
  justify-content: space-between;
  font-family: var(--title-font);
  font-size: 16px;
  width: 100%;
  height: 89px;
  margin-right: 50px;
}

.total-box .values {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.total-box.state-good .values {
  color: var(--main-green-color);
}

.total-box.state-medium .values {
  color: var(--yellow-warning-color);
}

.total-box.state-bad .values {
  color: var(--red-error-color);
}

.total-box .score {
  font-size: 33px;
}

/* Coach */
.coach-total-box .values {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.coach-total-box.state-good .values {
  color: var(--main-green-color);
}

.coach-total-box.state-medium .values {
  color: var(--yellow-warning-color);
}

.coach-total-box.state-bad .values {
  color: var(--red-error-color);
}

.coach-total-box .score {
  font-size: 33px;
  width: 100%;
}

/* Diagrams */

.diagram {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.diagram .title {
  font-family: var(--title-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.diagram .drop-downs {
  display: flex;
  gap: 8px;
  margin: 4px 0;
}

.diagram summary,
.diagram li {
  font-family: var(--button-font);
  background: var(--main-background-gradient);
}

.diagram .chart {
  /* background-image: url(./assets/img/chart-grid-background.png),
    linear-gradient(170deg, transparent 40%, var(--main-green-color) 200%); */
  min-height: 200px;
}

.chart-container {
  position: relative;
  background-image: url("./assets/img/chart-grid-background.png"),
    linear-gradient(170deg, transparent 40%, var(--main-green-color) 200%);
  min-height: 200px;
}

.chart-container canvas {
  position: relative;
  z-index: 1;
}

/*  */

.card-large {
  background: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

/* .card-large .picture {
  height: 230px;
  width: 100%;
  background: var(--main-background-gradient);
} */
.dish-icon {
  width: 24px;
  height: 24px;
}
.dish-icon img {
  width: 24px;
  height: 24px;
}
.card-large .title {
  font-family: var(--title-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-large .subtitle {
  font-family: var(--title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-large .description {
  font-family: var(--paragraph-font);
}

/* Card with thumbnail */

.card-with-thumbnail {
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  gap: 1em;
  min-height: 90px;
  background: var(--main-background-gradient);
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.card-with-thumbnail a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  width: 100%;
  text-decoration: none;
}

.card-with-thumbnail .thumbnail img {
  max-height: 48px;
  max-width: 48px;
}

.card-with-thumbnail .text {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}

.card-with-thumbnail .title {
  color: var(--neutral-white-200);
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-with-thumbnail .subtitle {
  color: var(--neutral-white-200);
  font-family: var(--button-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* card-checkbox */

.card-checkbox {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: var(--neutral-black-600, #161616);
}

.card-checkbox .title {
  color: var(--neutral-white-200);
  font-family: var(--button-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-checkbox .subtitle {
  color: var(--neutral-white-200);
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-checkbox .description {
  color: var(--neutral-white-200);
  font-family: var(--button-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* text with progress bar */

.card-text-with-progress-bar {
  display: flex;
  width: 95%;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
  color: #d0feb7;
  gap: 4px;
}

.card-text-with-progress-bars {
  display: flex;
  width: 95%;
  flex-direction: column;
  padding: 0 12px;
  color: #d0feb7;
  gap: 4px;
}

.card-text-with-progress-bars .info {
  font-family: var(--button-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.card-text-with-progress-bars .description {
  font-family: var(--paragraph-font);
  font-size: 23px;
  font-style: normal;

  line-height: normal;
}

/* blowing up default browser stules */
.card-text-with-progress-bars progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 12px;
  border: none;
  height: 4px;
  width: inherit;
  background: var(--main-background-gradient);
}

.card-text-with-progress-bars progress[value]::-webkit-progress-bar {
  background: var(--main-background-gradient);
}

.card-text-with-progress-bars progress::-webkit-progress-value {
  background-color: #d0feb7;
  border-radius: 12px;
}

.card-text-with-progress-bars progress::-moz-progress-bar {
  background-color: #d0feb7;
}

/* Styles for FLASHING IN RED progress bars */

.card-text-with-progress-bars progress.flashtored::-webkit-progress-value {
  background-color: var(--red-error);
  transition: background-color 500ms;
}

.card-text-with-progress-bars progress.flashtored::-moz-progress-bar {
  background-color: var(--red-error);
  transition: background-color 500ms;
}

.card-text-with-progress-bar .info {
  font-family: var(--button-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-text-with-progress-bar .description {
  font-family: var(--paragraph-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* blowing up default browser stules */
.card-text-with-progress-bar progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 12px;
  border: none;
  height: 4px;
  width: inherit;
  background: var(--main-background-gradient);
}

.card-text-with-progress-bar progress[value]::-webkit-progress-bar {
  background: var(--main-background-gradient);
}

.card-text-with-progress-bar progress::-webkit-progress-value {
  background-color: var(--green-primary-500);
  border-radius: 12px;
}

.card-text-with-progress-bar progress::-moz-progress-bar {
  background-color: var(--green-primary-500);
}

/* Styles for FLASHING IN RED progress bars */

.card-text-with-progress-bar progress.flashtored::-webkit-progress-value {
  background-color: var(--red-error);
  transition: background-color 500ms;
}

.card-text-with-progress-bar progress.flashtored::-moz-progress-bar {
  background-color: var(--red-error);
  transition: background-color 500ms;
}

/* card food */

.card-food {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--neutral-white-200);
  /* background: var(--main-background-gradient); */
  width: 80px;
  height: 80px;
  /* padding: 15px 8px; */
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.card-food .title {
  font-family: var(--paragraph-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-food .description {
  font-family: var(--paragraph-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* card with actions */

.card-with-actions {
  background: var(--main-background-gradient);
  border-radius: 4px;
  min-height: 90px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.card-with-actions .title {
  color: var(--neutral-white-200);
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: auto;
}

.card-with-actions .action {
  text-align: center;
  cursor: pointer;
}

/* card atleta */

.card-atleta {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--neutral-white-200);
  background: var(--main-background-gradient);
  padding: 20px 16px;
  justify-content: space-between;
  align-items: center;
}

.card-atleta a {
  min-width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-atleta .title {
  font-family: var(--title-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-atleta .description {
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
}

.card-atleta .description span {
  color: var(--neutral-black-300);
}

/* Cards allenamento */

/* Uses SVG Circle as progress bar:
		https://developer.mozilla.org/en-US/docs/Web/SVG/Element/circle

		Calling setCircularProgress(int: percent, string: timeLeft) in js/component-circular-progress-bar.js
		sets the progress and time left to display.

		It performs no calcs on the timer, so you should calc how much % of a given time is left yourself.
	*/

.card-allenamento {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
  /* Change to 100% on desktop */
  width: auto;
}

.card-allenamento .desktop-container {
  display: flex;
  /* Change to row on desktop */
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* border: 1px solid; */
}
.excercise-box {
  padding: 20px;
  border-radius: 15px;
  background: linear-gradient(180deg, #1e1e1e 0%, #0c0c0c 100%);
}

excercise-scrollable-container {
  height: 300px; /* Set the height of the container */
  overflow-y: auto; /* Show scrollbar only when content overflows */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #4caf50 #e0e0e0; /* For Firefox */
}

/* Customize the scrollbar for WebKit browsers like Chrome, Safari */
.excercise-scrollable-container::-webkit-scrollbar {
  width: 10px;
}

.excercise-scrollable-container::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.excercise-scrollable-container::-webkit-scrollbar-thumb {
  background-color: #4caf50;
  border-radius: 10px;
  border: 2px solid #e0e0e0;
}
.card-allenamento .desktop-container > div {
  width: 100%;
}

.card-allenamento .exercise-list .question {
  display: none;
}

.card-allenamento .exercise {
  padding: 1em;
}

.card-allenamento .buttons {
  margin-bottom: 1em;
}

.card-allenamento .exercise,
.card-allenamento .clock-and-info {
  border-radius: 8px;
  /* background: var(--main-background-gradient); */
}

.card-allenamento .video-link a,
.card-allenamento .video-link a:visited {
  text-decoration: none;
  color: var(--neutral-black-400);
}

.card-allenamento .sets {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.card-allenamento .outline {
  color: var(--main-green-color);
}

.card-allenamento .title {
  color: var(--neutral-white-200);
  font-family: var(--title-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-allenamento .info {
  color: var(--neutral-white-200);
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-align: start;
  line-height: normal;
}
.card-allenamento .header {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}

.card-allenamento .header,
.card-allenamento .clock {
  padding: 1em;
}

.exercise-list,
.card-allenamento .clock-and-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.card-allenamento .buttons > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}

.card-allenamento .buttons a {
  height: 32px;
  width: 100%;
}

.card-allenamento .clock {
  /* background: url(/img/circular-progress-bar.svg) no-repeat center; */
  align-items: center;
  justify-content: center;
  align-self: center;
  /* width: 107px;
  height: 107px; */

  font-family: var(--title-font);
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-allenamento .clock .progress-ring circle {
  stroke: var(--main-green-color);
  stroke-width: 3px;
  fill: transparent;
}

.card-allenamento .clock .progress-ring text {
  fill: var(--main-text-color);
  dominant-baseline: middle;
  text-anchor: middle;
}

.card-allenamento .buttons .timer-concluso.count-up {
  display: flex;
  flex-direction: column;
  width: initial;
}

.card-allenamento .buttons .timer-concluso.count-up a {
  width: initial;
}

/* card-allenamento-feedback-input-button */
.card-allenamento-feedback-input-button {
  display: flex;
  width: 288px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

/* Search */

.search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;

  background: var(--main-background-gradient);

  color: var(--neutral-black-300);
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search-bar .icon.right {
  cursor: pointer;
}

.search-bar div,
.search-bar input,
.search-bar button,
.search-bar a,
.search-bar img {
  display: flex;
}

.search-bar input {
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: none;
  color: var(--neutral-white-200);
  font-family: var(--paragraph-font);
  text-align: left;
}

.search-bar input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.search-bar .input-textarea {
  position: relative;
  top: 5%;
  width: 100%;
}

.search-bar.focus {
  border: 1px solid var(--blue-secondary-500);
}

/* Modals */

.modal {
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1.5em;
  background: var(--main-background-gradient);
  border-radius: 8px;
}

.modal.show {
  display: flex;
  position: fixed;
  top: 40%;
  margin-top: var(mobile-header-height);
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 100;
  width: 80vw;
  max-width: var(--slim-desktop-width);
  max-height: 80vh;
  overflow: auto;
  outline: 100vw solid rgb(0, 0, 0, 0.6);
  /* border: 1px dotted var(--green-primary-500); */
}

.modal.hide {
  display: none;
}

.modal > .title,
.modal .upper-selector .title {
  font-family: var(--title-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

.modal .close {
  display: flex;
  justify-content: right;
  cursor: pointer;
}

.modal .title,
.modal .salva-remove,
.modal .logout-buttons,
.modal .link-group {
  text-transform: uppercase;
}

.modal .shopping-list,
.modal .shopping-list span,
.modal .shopping-list a,
.modal .shopping-list a:visited {
  display: flex;
  gap: 1em;
  color: var(--main-text-color);
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.modal .aggiungi-ai-preferiti,
.modal .aggiungi-ai-preferiti span,
.modal .aggiungi-ai-preferiti a,
.modal .aggiungi-ai-preferiti a:visited {
  display: flex;
  gap: 1em;
  color: var(--main-text-color);
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.modal .date-picker {
  width: 100%;
}

.modal .link-group {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.modal .circle {
  height: 24px;
  width: 24px;
  background-color: var(--blue-secondary-600);
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5em;
}

.modal .upper-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--neutral-black-300);
}

.modal .nutrition-facts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.modal .single-fact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: var(--green-primary-500);
  font-family: var(--paragraph-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Tables */

.athletes-table {
  width: 100%;
}

.athletes-table a,
.athletes-table a:hover,
.athletes-table a:visited {
  color: var(--main-text-color);
  text-decoration: none;
}

.athletes-table th,
.athletes-table td {
  padding: 0.5em;
}

.athletes-table th {
  text-align: left;
  background-color: var(--neutral-black-700);
  border: 1px solid grey !important;
  font-weight: normal;
}

.athletes-table th > img {
  float: right;
}

.athletes-table tr:nth-child(odd) {
  background-color: var(--neutral-black-700);
}

.athletes-table tr:nth-child(even) {
  background-color: var(--neutral-black-500);
}

.dataTables_filter {
  margin-bottom: 0.5em;
}

.dataTables_length option {
  background-color: var(--neutral-black-500);
}

/* Task Editor */

.task-editor {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  /* background-color: red; */
  border-bottom: 1px solid #565656;

  /* background-color: red; */
}

.task-border {
  border-bottom: 1px solid gray;
}
.task-editor-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.task-editor .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 23px;
  font-family: var(--paragraph-font);
}

.task-editor .title-two {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-family: var(--paragraph-font);
}

.task-editor .title img {
  cursor: pointer;
}

.task-editor input {
  background-color: transparent;
  color: white;
  border: none;
}

/* form validation */

.input-control input:focus {
  outline: 0 !important;
}

.input-control.success {
  border: 1px solid green !important;
}

.input-control.error {
  border: 1px solid red !important;
}
.input-control .error {
  color: #ff3860 !important;
  font-size: 9px;
  height: 13px;
  float: right;
}

/*  */
.custom-select-container {
  position: relative;
  width: 100%;
  /* border: 1px solid red; */
}
.custom-select-container > div,
.custom-select-container > div > button {
  width: 100% !important;
}

/*  */
.show-password {
  display: flex;
  align-items: center;
}

.date-picker .error {
  color: #ff3860 !important;
  height: 1px;
  padding-left: 1em;
}

/* Drag & drop Meal compositor */

.meal-compositor {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 70%;
  height: 73vh;
  /* background-color: red; */
  background-image: url("https://s3-alpha-sig.figma.com/img/b4dd/ec8c/3894c4b37214c976575cd88fc3ca5655?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=oNP0mvPB1FdakKZ~ebo0NxgAoIVZawZSPalbC5-jIwZ0fEV2hdBzwG2kBsdeKgu8nJSrqynhyLy7exiu~XLAIzpSPDcrLRFEdjGU9esvId6M2xXXpb0NjJ6qdSopBooPiVcVBDEmO-DL~JFz9pkQEYDBS6mjtiIaZBY25jpjHxXSFdglgsqoy9ZJefHgoOVl8BLCq-RrU2OVMyKJgEln2jw4WNhjL0miy9GHSzhW3iXO0EnO7wzYZwszGDvzvd0KBlwWaVE12YktkWWkkJOrXXUiDHUpWb8uxXsW3NoXc9H5sVf-AP-YM68jCxXdUIS4IEVDSF8BOuN9HtSNu4qDUA__");
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  position: relative;
}
.step-three-btn {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral-black-500);
  background-color: var(--main-green-color);
  font-size: 16px;
  font-family: var(--button-font);
  text-decoration: none;
  clip-path: polygon(1em 0%, 100% 0%, calc(100% - 1em) 100%, 0% 100%);
  border: none;
  cursor: pointer;
}

.step-three-btn-container {
  padding: 0px 20px;
}
.step-three-btn-container1 {
  padding: 0px 20px;
}

.meal-compositor-flex {
  display: flex;
  gap: 20px;
  /* background: var(--main-background-gradient); */
  /* background-color: red; */
  border-radius: 8px;
}

.meal-composition-card-two {
  width: 30%;
}
.meal-compositor .progress-bar-group {
  display: flex;

  flex-direction: row;
}
.dragAndDropBox {
  min-height: 550px;
}

.meal-compositor .dropped-food,
.meal-compositor .food-horizontal-scroll {
  display: flex;
  flex-wrap: wrap;
  gap: 0.3em;
  z-index: 99;
}
.mealBox {
  display: flex;
  flex-wrap: wrap;
}

.drag-and-drop-plate {
  height: 630px;
  margin: 0 auto;
  max-width: 800px;
  background-image: url("./assets/images/plate.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  top: -10px;
}
.drag-and-drop-plate .drop-container {
  position: absolute;
  top: 30% !important;
  left: 25%;
  width: 50%;
  max-height: 10px !important;
  border-radius: 100%;
  padding: 50px 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-gap: 20px; */
}
@media only screen and (max-width: 1380px) {
  .drag-and-drop-plate .drop-container {
    top: 15%;
    width: 55%;
    left: 23%;
    height: 70%;
  }
}
.drop-container .text {
  font-size: 12px;
}

.meal-compositor .progress-bar-group .card-text-with-progress-bar {
  border-right: 1px dashed var(--green-primary-500);
}

.meal-compositor .progress-bar-group .card-text-with-progress-bar:last-child {
  border-right: none;
}

.meal-compositor .food-horizontal-scroll .example {
  /* display: none; */
}

.meal-compositor .plate-droparea img,
.meal-compositor .food-horizontal-scroll img {
  /* Prevents the img from being dragged, because
	we want only the div to be */
  pointer-events: none;
}

.meal-compositor .plate-droparea {
  background-image: url("https://s3-alpha-sig.figma.com/img/b4dd/ec8c/3894c4b37214c976575cd88fc3ca5655?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=oNP0mvPB1FdakKZ~ebo0NxgAoIVZawZSPalbC5-jIwZ0fEV2hdBzwG2kBsdeKgu8nJSrqynhyLy7exiu~XLAIzpSPDcrLRFEdjGU9esvId6M2xXXpb0NjJ6qdSopBooPiVcVBDEmO-DL~JFz9pkQEYDBS6mjtiIaZBY25jpjHxXSFdglgsqoy9ZJefHgoOVl8BLCq-RrU2OVMyKJgEln2jw4WNhjL0miy9GHSzhW3iXO0EnO7wzYZwszGDvzvd0KBlwWaVE12YktkWWkkJOrXXUiDHUpWb8uxXsW3NoXc9H5sVf-AP-YM68jCxXdUIS4IEVDSF8BOuN9HtSNu4qDUA__");
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.meal-compositor .plate-droparea .dropped-food {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
}

.meal-compositor .plate-droparea .dropped-food-img {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  display: flex;
}

.meal-compositor .plate-droparea .action {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.meal-compositor .list-of-recipes {
  background-color: black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.3em;
}

.meal-compositor .modal.dialog-composizione-piatto .controls .number::after {
  content: " g";
}

:host,
app-pre-allenamento-giorno-on {
  display: flex;
  width: 100%;
}

.link-video-circuito {
  width: auto;
  display: inline-block;
  padding: 0;
  clip-path: none;
}

.riposo-img {
  max-width: 100%;
  width: 100%;
  height: 500px;
}

.head-info,
.riposo.text {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 0.5em;
  margin: 1em 0;
  width: 100%;
}

.head-info .title,
.riposo.text .title {
  color: var(--neutral-white-200, #f6f6f6);
  font-family: var(--title-font);
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.riposo.text .subtitle {
  color: var(--neutral-white-200, #f6f6f6);
  font-family: var(--title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.info-container {
  color: var(--primary-300, #d0feb7);

  /* Buttons */
  font-family: var(--title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  margin-bottom: 1em;
}

.info-container > div {
  display: flex;
  gap: 0.5em;
}

.salta {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.salta .button {
  margin-top: var(--margin-to-avoid-header-overlap);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  margin-right: 1em;
  padding: 0.5em;
  color: var(--primary-500, #a0fc6e);
  font-family: var(--title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.video-player {
  position: relative;
}

.video-player img,
.video-player video {
  width: 100vw;
  height: 85vh;
}

#warmup-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  background: #171717;
  min-height: 100%;
}
#warmup-overlay .video-player {
  max-height: calc(100vh - 100px);
}

.timer {
  background-image: url(./assets/img/desktop-splash-background.jpg);
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  z-index: 100;
}

.timer .title {
  color: var(--neutral-white-200, #f6f6f6);
  text-align: center;
  font-family: var(--title-font);
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.timer .clock {
  color: var(--primary-500, #a0fc6e);
  font-family: var(--title-font);
  font-size: 128px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.feedback {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
}

.feedback .title {
  font-size: 54px;
  text-align: -webkit-center;
}

.feedback .progress-bar {
  margin-bottom: 3em;
  padding: 1em;
}

.feedback .progress-bar,
.feedback a {
  width: 80%;
  max-width: var(--slim-desktop-width);
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.title-container {
  /* background-color: blue; */
  margin-bottom: 20px;
}
.item-title {
  color: var(--neutral-white-200, #f6f6f6);
  font-family: var(--title-font);
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 30px;
  background: linear-gradient(to bottom, #1e1e1e, #0f0f0f, #0f0f0f);
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Equivalent to Tailwind's shadow-md */
  color: white;

  padding: 20px;
}
.item-container {
  display: flex;
  gap: 20px;
}
.item-img {
  background-color: #1e1e1e;
  width: 50px;
  height: 100%;
}

.description {
  margin-bottom: 5px;
}

.right-gap {
  padding-left: 16px;
  padding-top: 40px;
}
.string {
  margin-bottom: 10px;
  font-size: 16px;
  font-family: var(--paragraph-font);
  text-align: center;
}
.border {
  width: 100%;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  gap: 100px;
  border-radius: 8px;
  background: var(--main-background-gradient);
}
.allenamento-card-middle-btn {
  padding: 16px;
}
.allenamento-card-middle {
  border: 1px solid green;
  border-radius: 20px;
  height: 330px;
  padding: 10px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.allenamento-card-right {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.allenamento-card-middle-description {
  font-size: 14px;
  font-weight: 300;
  color: gray;
}

.progress-container {
  text-align: center;

  color: #ffffff; /* Adjust color as needed */
}

.progress-text {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.progress-bar {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.progress-segment {
  width: 50px;
  height: 4px;
  background-color: #ffffff; /* Default color */
  margin: 0 5px;
  /* border-radius: 5px; */
  clip-path: polygon(1em 0%, 100% 0%, calc(100% - 1em) 100%, 0% 100%);
}

.progress-segment.completed {
  background-color: #a0fc6e; /* Completed color */
}

.progress-week {
  font-size: 1em;
}

@media screen and (min-width: 768px) {
  .settimana-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5em;
  }

  .step-indicator {
    margin: 0 30%;
  }

  .measurements {
    flex: auto;
  }

  .button-default-no-icon {
    width: 100%;
    font-size: 16px;
  }

  .info-container {
    flex-direction: row;
  }

  .card-allenamento {
    width: 100%;
  }

  .card-allenamento .desktop-container {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 columns for screens 768px and wider */
  }
}

@media screen and (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* 3 columns for screens 1024px and wider */
  }
}

.training-video {
  width: 100%;
  margin: 50px auto;
  height: 80vh;
}
.training-video video {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain;
}

.video-parent {
  position: relative;
}
.next-step-button {
  position: absolute;
  top: 20px;
  right: 20px;
}
.next-step-button-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 1em;
  color: var(--primary-500, #a0fc6e);
  cursor: pointer;
}
.cards-max-width {
  max-width: 650px;
}
.training-step-two-flex {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.step-gap {
  padding-left: 16px;
  padding-top: 40px;
}
.step-width {
  width: 700px;
  /* background-color: red; */
}

.step-gap-left {
  padding-left: 16px;
}

.nutrition-flex {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.stepOne-nutrition-fact {
  display: flex;
  min-width: 400px;
  margin: 0 auto;
}
.link-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.link-list a {
  color: var(--primary-500, #a0fc6e);
  font-family: var(--title-font);
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.inner {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start;
}
.fonte {
}
.inner .title {
  color: var(--neutral-white-200);
  font-family: var(--title-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
.nutrition-title {
  color: var(--neutral-white-200);
  font-family: var(--title-font);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.facts {
  display: flex;
  margin: 0 auto;
  min-width: 400px;
}
.navigation-bar-step-two {
  max-width: 450px;
  margin: 0 auto;
}
/* .width-step-two {
  max-width: 450px;
  margin: 0 auto;
} */

.top-bar .icon {
  background: none;
  border: none;
  cursor: pointer;
}

/* Step Indicator */
.step-indicator {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--neutral-white-200);
  text-align: center;
  font-family: var(--title-font);
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step-indicator .description {
  color: var(--neutral-black-300);
  font-family: var(--button-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step-indicator .description,
.step-indicator .title {
  text-align: left;
}

.step-indicator .step-area {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.step-indicator .step {
  height: 6px;
  width: 100%;
  background-color: white;
  clip-path: polygon(0.2em 0%, 100% 0%, calc(100% - 0.2em) 100%, 0% 100%);
}

.step-indicator .step.active {
  height: 6px;
  width: 100%;
  background-color: var(--green-primary-500);
  clip-path: polygon(0.2em 0%, 100% 0%, calc(100% - 0.2em) 100%, 0% 100%);
}

/* Loader */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader-container {
  text-align: center;
  color: white;
}
.sidebar-flex-content {
  display: flex;
  flex-direction: column;
}
.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-btn {
  background-color: transparent;
  color: white;
  border: 2px solid #a0fc6e;
  padding: 6px 16px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  font-family: var(--title-font);
  gap: 10px;
  /* height: 16px; */

  align-items: center;
  /* width: 200px; */
}

.dropdown-icon {
  margin-left: auto;
  transition: transform 0.2s ease-in-out;
}

.dropdown-icon.open {
  transform: rotate(180deg);
}

.dropdown-menu-ul {
  position: absolute;
  top: 10;
  left: 0;
  background-color: transparent;
  color: white;
  border: 1px solid #a0fc6e;
  width: 100%;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-menu-ul::-webkit-scrollbar {
  width: 8px;
}
.dropdown-menu-ul::-webkit-scrollbar-track {
  background: #0f0f0f;
}
.dropdown-menu-ul::-webkit-scrollbar-thumb {
  background-color: #3b8004;
  border-radius: 10px;
}

.dropdown-menu-ul ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu-ul li {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #a0fc6e;
}

.dropdown-menu-ul li:hover {
  background-color: #555;
}
.small-input {
  background-color: #565656;
  font-family: var(--paragraph-font);
  padding: 4px 12px;
  font-size: 18px;
  /* width: 68px;
  height: 32px; */
  width: 68px;
  border-radius: 4px;
  text-align: center;
  border: none;
  outline: none;
  color: white;
}

.xs-input {
  background-color: #565656;
  font-family: var(--paragraph-font);
  padding: 4px 12px;
  font-size: 18px;
  /* width: 68px;
  height: 32px; */
  width: 45px;
  border-radius: 4px;
  text-align: center;
  border: none;
  outline: none;
  color: white;
}

.input-btn {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #a0fc6e;
  color: #a0fc6e;
  font-family: var(--title-font);
  font-size: 16px;
  border-radius: 4px;
  padding: 5px 12px;
  width: 20%;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.small-dropdown-container {
  position: relative;
  display: inline-block;
}

.small-dropdown-btn {
  background-color: transparent;
  color: white;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  font-family: var(--paragraph-font); /* gap: 10px; */
  align-items: center;
  justify-content: center;
  width: 104px;
  background-color: #565656;
  text-align: center;
  padding: 4px 12px;
}

.small-dropdown-icon {
  margin-left: auto;
  transition: transform 0.2s ease-in-out;
}

.small-dropdown-icon.open {
  transform: rotate(180deg);
}

.small-dropdown-menu-ul-food {
  position: absolute;
  top: 10;
  left: 0;
  background-color: transparent;
  color: white;
  font-family: var(--paragraph-font);
  width: 100%;
  z-index: 99;
}
.small-dropdown-menu-ul {
  position: absolute;
  top: 10;
  left: 0;
  background-color: transparent;
  color: white;
  font-family: var(--paragraph-font);
  width: 100%;
  z-index: 99;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}
.small-dropdown-menu-ul::-webkit-scrollbar {
  width: 8px;
}
.small-dropdown-menu-ul::-webkit-scrollbar-track {
  background: transparent;
}
.small-dropdown-menu-ul::-webkit-scrollbar-thumb {
  background-color: #3b8004;
  border-radius: 10px;
}

.small-dropdown-menu-ul ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.small-dropdown-menu-ul-food ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.small-dropdown-menu-ul li {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #a0fc6e;
  background-color: #333;
}

.small-dropdown-menu-ul-food li {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #a0fc6e;
  background-color: #333;
}

.small-dropdown-menu-ul li:hover {
  background-color: #555;
}
.small-dropdown-menu-ul-food li:hover {
  background-color: #555;
}
.ml-dropdown {
  margin-left: 20px;
}
.dots-dropdown {
  background-color: transparent;
  width: 200px;
  margin-left: 20px;
  margin-top: 2px;
  font-family: var(--title-font);
}
.dots-dropdown ul {
  list-style: none;
  padding-left: 0px;
  border: 1px solid #a0fc6e;
}
.dots-dropdown ul li {
  text-decoration: none;
  padding: 8px 16px;
  font-size: 16px;
  border-bottom: 1px solid #a0fc6e;
}
.dots-dropdown ul li:first-child {
  text-align: center;
}
.bg-ul {
  background-color: #333;
  font-family: var(--title-font);
}
.right-side-content {
  display: flex;
  align-items: end;
  justify-content: end;
  margin: 20px 0px;

  font-family: var(--title-font);
}

.right-side-content p {
  color: #da1e28;
  font-size: 16px;
}
.drop-index {
  z-index: 999;
}
.padding-select {
  padding: 6px 16px;
}
.giorno-container {
  margin: 20px 0px;
}

.save-btn {
  background-color: transparent;
  width: 100px;
  margin-top: 10px;
  font-size: 16px;
  font-family: var(--button-font);
  color: #a0fc6e;
  border: none;
  outline: none;
  cursor: pointer;
}
.custom-select {
  position: relative;
  display: inline-block;
  width: 200px;
}

/* The select element */
.custom-select select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none; /* No border */
  background-color: #0096ff; /* Blue background */
  color: white; /* White text */
  border-radius: 4px; /* Slight rounded corners */
  appearance: none; /* Removes default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border: none;
  outline: none;
}

/* Custom arrow */
.custom-select::after {
  content: "▲"; /* Upward arrow when dropdown is closed */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 12px;
  color: white;
}

/* Custom arrow when select is opened */
.custom-select select:focus + .custom-select::after {
  content: "▼"; /* Downward arrow when dropdown is open */
}

/* Dropdown options */
.custom-select select option {
  background-color: #0096ff; /* Same blue background for options */
  color: white; /* White text */
  border: none;
  outline: none;
  border: 1px solid red;
}
.custom-select select option:hover {
  background-color: red;
}
