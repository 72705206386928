.grid-table {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 10px;
  font-size: 18px;
  font-family: var(--paragraph-font);
}
.head {
  background-color: #000;
  padding: 10px 5px;
}
.head-col {
  border-right: 1px solid #565656;
}
.head-col,
.colss {
  /* min-width: 150px; Ensure the columns have enough width for scrolling */
  padding: 10px;
}

.head .head-col:nth-child(1) {
  border-left: 1px solid #565656;
}

.col {
  background-color: #565656;
  text-align: center;
  margin: 10px 0;
  border-radius: 4px;
  /* padding: 5px 0px; */
}

.col-padding {
  padding: 4px 12px;
}

.colss {
  margin: 10px 0;
  border-radius: 4px;
  /* padding: 5px 0px; */
  width: 150px;
  word-break: break-all !important;
  /* border: 1px solid red; */
}
.gridd {
  display: grid;
}
.scrollTable {
  overflow-x: auto;
  max-width: 100%;
  max-height: 400px;
}

.data-table {
  margin-left: 0;
  width: 100%;
  min-width: 800px;
  font-family: var(--paragraph-font);
}
.data-table thead td {
  padding: 10px;
  text-align: center;
  border-right: 1px solid #565656;
}
.data-table td {
  padding: 20px;
  text-align: center;
}
.data-table thead {
  padding: 10px !important;
}
.overflow {
  display: grid;
  overflow-x: auto; /* Enable horizontal scroll */
  white-space: nowrap; /* Prevent wrapping of content */
}
.grid-table .col:nth-child(1) {
  background-color: transparent;
  text-align: left;
}
.grid-table-two {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  gap: 10px;
  font-size: 18px;
  font-family: var(--paragraph-font);
}

.grid-table-two-cols-head {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  font-size: 18px;
  font-family: var(--paragraph-font);
}
.grid-table-two-cols {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  font-size: 18px;
  font-family: var(--paragraph-font);
}
.grid-table-four-cols-head {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px;
  font-size: 18px;
  font-family: var(--paragraph-font);
}
.grid-table-four-cols {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px;
  font-size: 18px;
  font-family: var(--paragraph-font);
}
.grid-activity {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 10px;
  font-size: 18px;
  font-family: var(--paragraph-font);
  /* background-color: red; */
  padding-left: 30px;
  overflow-x: auto;
}
.grid-activity p {
  margin: 0px;
}
.grid-activity.activity-head {
  /* background-color: green; */
  padding-bottom: 20px;
  font-family: var(--title-font);
  font-size: 24px;
  margin-top: 20px;
}

.activity-span {
  /* display: flex;
  gap: 10px; */
  grid-column: span 2;
}
.progression-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: var(--title-font);
}
.grid-activity select {
  outline: none;
  background-color: #565656;
  color: #fff;
  font-family: var(--paragraph-font);
  width: 100%;
  padding: 4px 0px;
  border: none;
  border-radius: 4px;
}
.grid-activity select option {
  background-color: #565656;
}
.testttttttt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 50%;
  padding-left: 30px;
}

.itemmmmmmmm {
  flex: 1 1 calc(30.33% - 10px); /* Equal width for 3 items per row */
  margin: 5px;
  background-color: red;
  box-sizing: border-box;
}
