.main-profile-link-group .main-profile-link p {
  font-family: var(--paragraph-font);
  font-size: 16px;
}

.main-profile-link-group {
  display: flex;
  /* gap: 1em; */
  flex-direction: column;
  align-self: flex-start;
  margin-left: 30px;
}

.main-profile-link,
.main-profile-link:hover,
.main-profile-link:visited {
  color: var(--main-text-color);
  text-decoration: none;
  display: flex;
  gap: 0.5em;
}

.content-right-sidebar .width {
  width: 30%;
}

.content-right-sidebar .noteButton {
  width: 90%;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .content-right-sidebar .width {
    width: 100%;
  }
  .content-right-sidebar .noteButton {
    width: 50%;
  }
}

.headerCalendrio .icon {
  background: none;
  border: none;
  cursor: pointer;
}
