.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.videpbanner {
  height: 200px;
}

.counter-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  color: #fff;
  font-family: Arial, sans-serif;
  /* margin: 20px; */
  margin-bottom: 30px;
}

.counter-label {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: var(--neutral-white-200, #f6f6f6);

  /* Headings/h5 */
  font-family: var(--title-font);
}

.counter-controls {
  display: flex;
  align-items: center;
}

.counter-btn {
  width: 40px;
  height: 40px;
  border: 2px solid #7fff00;
  background-color: transparent;
  color: #7fff00;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  /* margin: 0 50px; */
}

.counter-value {
  font-size: 1.5em;
  color: #fff;
  margin: 0 50px;
}
